import React, { Component, Fragment } from "react";
import { getCountries } from "../../actions/country";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import sectIndicator from "../../images/sect_indicator.svg";


import Helper from "./../Helper/Helper";
import DocumentNumber from "../Form/DocumentNumber";
import Error from "../Form/Error";
import Select from "../Form/Select";

import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";

import { config as serverUploadConfig } from "../../helpers/serverUpload";

import { FilePond, registerPlugin } from "react-filepond";

import {
  faBan,
  faCheckSquare,
  faChevronCircleDown,
  faChevronCircleUp,
  faHourglassStart,
  faPager,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";

import certificateIcon from "../../images/certifi.png";

// Material table
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import DialogModalGlobal from "./DialogModalGlobal";

import LoadPage from "../Load/LoadPage";

import NumberFormat from "react-number-format";

import multipleUsersIcon from "../../images/multiple-users.png";

import {
  getBeforeCertificate,
  getBeforeCertificateKg,
  getBeforePDF,
} from "./../../actions/projectEmitCertificationModificationRequest";

import actionProjectCompanySerial from "../../actions/projectCompanySerial";
import { getReasonUsingCertificate } from "./../../actions/projectEmitCertification";
import { saveProjectEmitCertificationConfirm } from "./../../actions/projectEmitCertificationConfirm";

import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Dropdown,
} from "react-bootstrap";

import {
  getAllProjectEmitCertification,
  getProjectEmitCertification,
  projectEmitCertificationUpdate,
} from "../../actions/project";

import { bindActionCreators } from "redux";

import { platform } from "../../Style/index";

import {
  faSortUp,
  faSortDown,
  faSort,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const actions = {
  getCountries,
};

class ProjectEmitCertificationGlobalList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoad: true,
      projectSerialYear: [],
      keyValidationModal: false,
      certificateKey: "",
      emitcertifications: [],
      keyError: "",
      page: 0,
      rowsPerPage: 5,
      emitcertificationsFilter: [],
      sortConfig: { key: null, direction: "asc" },
      comment: [],
      serialsElegible: [],
      yearValidation: 0,
      showModal: false,
      isPassive: false,
      isDisabledSaveCancel: false,
      passiveSubjectTypeDocument: "",
      comment: [],
      passiveSubjectDocumentNumber: "",
      passiveSubjectName: "",
      passiveSubjectVerificationDigit: "",
      passiveSubjectIsVerificationDigit: false,
      isPublicBeneficiary: false,
      modalCommentsGlobal: false,

      isPublicReason: false,
      countryId: "",
      passiveSubjectCountryId: "",
      formatDocumentNumber: "",
      passiveSubjectFormatDocumentNumber: "",
      optionsBonus: [],
      useBonus: "0",
      totalCancellation: false,
      typeSendCode: "email",
      isAlertConfirm: false,
      changeUseBonus: false,
      changeUseBonusCheck: false,
      informationUserEnd: false,
      informationUserEndCheck: false,
      informationTaxpayerSubject: false,
      informationTaxpayerSubjectCheck: false,
      termsAndConditionsCheck: false,
      useBonusId: null,
      typeDocument: "",
      documentNumber: "",
      name: "",
      certifierCancelNull: 0,
      available: 0,
      certifierCancelled: 0,
      issuedQuantity: 0,
      certifierAvailable: 0,
      viewDetail: false,
      totalCancellationState: false,
      certifierQuantity: 0,
      documentsWithdrawalCertificate: [],
    };
  }

  sortData = (key) => {
    let { sortConfig, emitcertifications, emitcertificationsFilter } = this.state;
    let direction = "asc";

    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }

    const sortedCertifications = [...emitcertificationsFilter].sort((a, b) => {
      const valueA = a[key];
      const valueB = b[key];

      if (typeof valueA === "number" && typeof valueB === "number") {
        return direction === "asc" ? valueA - valueB : valueB - valueA;
      } else if (typeof valueA === "string" && typeof valueB === "string") {
        return direction === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else if (typeof valueA === "boolean" && typeof valueB === "boolean") {
        return direction === "asc"
          ? valueA === valueB
            ? 0
            : valueA
            ? -1
            : 1
          : valueA === valueB
          ? 0
          : valueA
          ? 1
          : -1;
      }
      return 0;
    });

    this.setState({
      sortConfig: { key, direction },
      emitcertificationsFilter: sortedCertifications,
    });
  };


  setClearString = (string) => {
    if (string) {
      string = string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return string;
    } else {
      return "";
    }
  };

  transformLocation = (data) => {
    return data
      ? data.map((location) => ({
          value: location.id,
          label: location.name,
          data: location.data,
        }))
      : [];
  };


  handleSearch = (event) => {

    const {emitcertifications} = this.state
    
    this.setState({
      searchText: event.target.value,
      page: 0,
      emitcertificationsFilter: emitcertifications
        .filter(
          (row) =>
            this.setClearString(JSON.stringify(row))
              .toLowerCase()
              .indexOf(
                this.setClearString(
                  event.target.value
                ).toLowerCase()
              ) > -1
        ),
    });
  }

  onClickDownload = (typeSelect, selectedInfo) => {
    const { transfers, emitcertifications, projectSerialYear } = this.state;
    this.setState({
      pageLoad: true,
    });

      actionProjectCompanySerial
        .formatGeneralDownload({
          emitcertifications: emitcertifications.map((ele) => ({
            "Fecha de retiro": ele.date,
            "Proyecto": ele.projectName,
            "Año": ele.year,
            "Cod. Vintage": ele.serial.toString().replace(/\s+/g, ''),
            "Cod. COLCERs retirados": ele.serialFinal.toString().replace(/\s+/g, ''),
            "Propósito del retiro": ele.reasonUsingCarbonOffsets,
            "Usuario final": `${ele.nitEnd} - ${ele.userEnd}`,
            "Sujeto pasivo": `${ele.passiveSubjectNit} - ${ele.passiveSubject}`,
            "COLCERs retirados": ele.quantity,
          })),
          typeSelect,
        })
        .then((response) => {
          if (response.url) {
            window.open(response.url, "_blank");
            this.setState({
              pageLoad: false,
            });
          } else {
            this.setState({
              pageLoad: false,
            });
          }
        });
   
  };

  onChangeCountry = (countryIdName, formatDocumentNumberName) => (event) => {
    const optionElement = event.target.childNodes[event.target.selectedIndex];
    const option = JSON.parse(optionElement.getAttribute("data-json"));
    this.setState({
      [countryIdName]: event.target.value,
      [formatDocumentNumberName]: option.formatDocumentNumber,
    });
  };
  
  onChange =
    (name, checked = false) =>
    (event) => {
      let isVerificationDigit = this.state.isVerificationDigit;
      let passiveSubjectIsVerificationDigit =
        this.state.passiveSubjectIsVerificationDigit;

      if (name === "typeDocument" || name === "passiveSubjectTypeDocument") {
        const optionElement =
          event.target.childNodes[event.target.selectedIndex];
        const option = JSON.parse(optionElement.getAttribute("data-json"));
        isVerificationDigit =
          name === "typeDocument"
            ? option.isVerificationDigit
            : isVerificationDigit;
        passiveSubjectIsVerificationDigit =
          name === "passiveSubjectTypeDocument"
            ? option.isVerificationDigit
            : passiveSubjectIsVerificationDigit;
      }

      if (checked) {
        return this.setState({
          [name]: event.target.checked,
        });
      }
      return this.setState({
        [name]: event.target.value,
        isVerificationDigit,
        passiveSubjectIsVerificationDigit,
      });
    };

  onClickDownloadCertificate = (id) => (event) => {
    this.setState({ pageLoad: true });
    getProjectEmitCertification(id).then((response) => {
      if (response.status) {
        this.setState({ pageLoad: false });
        window.open(response.urlPDF, "_blank");
      }
      this.setState({ pageLoad: false });
    });
  };

  onClickBeforeCertificate = (certificatedId, idx) => {
    this.setState({ pageLoad: true });
    getBeforeCertificate(certificatedId).then((response) => {
      if (!response.status) {
        this.setState({ pageLoad: false });
      }
      const beforeShow = false;
      let emitcertifications = this.state.emitcertifications;
      emitcertifications[idx].before = response.emitcertifications;
      emitcertifications[idx].showBefore = !emitcertifications[idx].showBefore
        ? !beforeShow
        : beforeShow;
      this.onClickBeforeCertificateKg(certificatedId, idx);
    });
  };

  onClickBeforeCertificateKg = (certificatedId, idx) => {
    getBeforeCertificateKg(certificatedId).then((response) => {
      if (!response.status) {
        this.setState({ pageLoad: false });
      }
      const beforeShow = false;
      let emitcertifications = this.state.emitcertifications;
      emitcertifications[idx].beforeKg = response.emitcertifications;
      emitcertifications[idx].showBeforeKg = !emitcertifications[idx]
        .showBeforeKg
        ? !beforeShow
        : beforeShow;
      this.setState({ emitcertifications, pageLoad: false });
    });
  };

  onClickGetBeforePDF = (certificatedId) => {
    this.setState({ pageLoad: true });
    getBeforePDF(certificatedId).then((response) => {
      if (response.urlPDF) {
        this.setState({ pageLoad: false });
        window.open(response.urlPDF, "_blank");
      }
      this.setState({ pageLoad: false });
    });
  };

  onClickUpdatedCertificate = (projectId, certificatedId, serial) => {
    let cancellation = false;
    actionProjectCompanySerial.getValidationSerial(serial).then((response) => {
      if (response.status === 0) {
        cancellation = true;
      }
    });

    getReasonUsingCertificate(certificatedId).then((response) => {
      if (response.status) {
        let optionsUseBonus = response.reasonUsingBonus.map((e) => ({
          ...e,
          data: { isPassive: e.isPassive },
        }));
        //se valida el año para mostrar la razon de uso del certificado
        if (response.projectYear < response.yearValidation) {
          optionsUseBonus = optionsUseBonus.filter((e) => e.value !== 1);
        }

        let passive = null;
        if (response.useBonusId === 1) {
          passive = 1;
        }

        this.sendVerificationCode();

        return this.setState({
          optionsBonus: optionsUseBonus,
          isPassive: passive ? passive : optionsUseBonus[0].isPassive,
          useBonus: optionsUseBonus[0].value,
          useBonusId: response.useBonusId,
          showModal: true,
          certificatedId,
          projectId,
          totalCancellationState: cancellation,
        });
      }
      return this.setState({
        isAlert: true,
        pageLoad: false,
        showModal: false,
        alert: {
          type: "danger",
          message: response.codeMessages,
        },
      });
    });
  };

  renderSortableHeader = (key, label) => {
    const { sortConfig } = this.state;
    return (
      <TableCell
        className="colcx-table-cell-header"
        style={{
          verticalAlign: "middle",
          cursor: "pointer",
        }}
        onClick={() => this.sortData(key)}
      >
        {label}{" "}
        {sortConfig.key === key ? (
          sortConfig.direction === "asc" ? (
            <FontAwesomeIcon icon={faSortUp} />
          ) : (
            <FontAwesomeIcon icon={faSortDown} />
          )
        ) : (
          <FontAwesomeIcon icon={faSort} />
        )}
      </TableCell>
    );
  };

  componentDidMount() {
    getAllProjectEmitCertification().then((response) => {
      if (response.status !== 1) {
        return this.props.history.push("/404");
      }
      this.setState({
        pageLoad: false,
        emitcertifications: response.emitcertifications,
        emitcertificationsFilter: response.emitcertifications,
      });
    });
  }

  handleClose = () => {
    this.cleanCheck();
    this.setState({ showModal: false });
  };

  onChangeUseBonu = (name) => (event) => {
    const optionElement = event.target.childNodes[event.target.selectedIndex];
    const option = JSON.parse(optionElement.getAttribute("data-json"));
    return this.setState({
      [name]: event.target.value,
      ...option,
    });
  };

  sendVerificationCode = () => {
    const { typeSendCode } = this.state;
    const { t } = this.props;
    const projectId = parseInt(this.props.match.params.projectId);

    this.setState({ pageLoad: true });

    saveProjectEmitCertificationConfirm({ projectId, typeSendCode }).then(
      (response) => {
        let typeAlert = "danger";
        let message = "";
        if (response.status !== 1) {
          message = t("errorMessage.generatingCode");
        }
        if (response.status === 1) {
          typeAlert = "success";
          message = t("successMessage.codeGenerated");
        }
        if (response.status === 1 && response.send === false) {
          typeAlert = "info";
          message = t("errorMessage.lastCode");
        }
        this.setState({
          pageLoad: false,
          isAlert: false,
          isAlertConfirm: true,
          showModal: true,
          alert: {
            type: typeAlert,
            message: message,
          },
        });
      }
    );
  };
  resendVerificationCode = () => {
    this.sendVerificationCode();
  };
  onSaveModify = () => {
    const { t } = this.props;
    const {
      useBonus,
      countryId,
      typeDocument,
      documentNumber,
      verificationDigit,
      isVerificationDigit,
      name,
      code,
      passiveSubjectCountryId,
      passiveSubjectTypeDocument,
      passiveSubjectDocumentNumber,
      passiveSubjectName,
      passiveSubjectVerificationDigit,
      passiveSubjectIsVerificationDigit,
      certificatedId,
      totalCancellation,
      termsAndConditionsCheck,
      changeUseBonusCheck,
      informationUserEndCheck,
      informationTaxpayerSubjectCheck,
      useBonusId,
      documentsWithdrawalCertificate,
    } = this.state;
    this.setState({ isAlert: false, pageLoad: true });
    let data;

    const documentsUpload = documentsWithdrawalCertificate.map((item) => {
      try {
        if (Object.keys(item.getMetadata()).length > 0) {
          return item.getMetadata();
        }
      } catch (e) {
        if (item.options && item.options.metadata) {
          return item.options.metadata;
        }
      }
      return JSON.parse(item.serverId);
    });

    if (documentsUpload.length <= 0) {
      return this.setState({
        isAlert: true,
        pageLoad: false,
        alert: {
          type: "danger",
          message: t("certificateUpdated.notDocument"),
        },
      });
    }

    //validation terms and conditions check
    if (!termsAndConditionsCheck) {
      return this.setState({
        isAlert: true,
        pageLoad: false,
        alert: {
          type: "danger",
          message: t("certificateUpdated.termsConditions"),
        },
      });
    }

    //validation variables is passive
    if (informationTaxpayerSubjectCheck) {
      if (
        passiveSubjectTypeDocument.toString().trim().length === 0 ||
        passiveSubjectDocumentNumber.toString().trim().length === 0 ||
        passiveSubjectName.toString().trim().length === 0 ||
        passiveSubjectCountryId.toString().trim().length === 0 ||
        (passiveSubjectIsVerificationDigit &&
          passiveSubjectVerificationDigit.toString().trim().length === 0)
      ) {
        return this.setState({
          isAlert: true,
          pageLoad: false,
          alert: {
            type: "danger",
            message: t("validationsMessage.youMustCompleteAllFields"),
          },
        });
      }
      data = {
        ...data,
        passiveSubject: {
          countryId: parseInt(passiveSubjectCountryId),
          typeDocumentId: parseInt(passiveSubjectTypeDocument),
          documentNumber: passiveSubjectDocumentNumber,
          isVerificationDigit: passiveSubjectIsVerificationDigit,
          verificationDigit: passiveSubjectVerificationDigit,
          name: passiveSubjectName,
        },
      };
    }
    if (
      !informationTaxpayerSubjectCheck &&
      !changeUseBonusCheck &&
      !informationUserEndCheck &&
      !totalCancellation &&
      !termsAndConditionsCheck
    ) {
      return this.setState({
        pageLoad: false,
        isAlert: true,
        alert: {
          type: "danger",
          message: t("validationsMessage.youMustCompleteAllFields"),
        },
      });
    }
    //validation reason using carbon off sets
    if (changeUseBonusCheck) {
      if (useBonus.toString().trim().length === 0) {
        return this.setState({
          pageLoad: false,
          isAlert: true,
          alert: {
            type: "danger",
            message: t("validationsMessage.youMustCompleteAllFields"),
          },
        });
      }
      if (useBonusId === useBonus) {
        return this.setState({
          pageLoad: false,
          isAlert: true,
          alert: {
            type: "danger",
            message: t("validationsMessage.useBonus"),
          },
        });
      }

      data = {
        ...data,
        reasonUsingCarbonOffsetsId: parseInt(useBonus),
      };
    }

    //validation variables final user
    if (informationUserEndCheck) {
      if (
        typeDocument.toString().trim().length === 0 ||
        documentNumber.toString().trim().length === 0 ||
        name.toString().trim().length === 0 ||
        countryId.toString().trim().length === 0 ||
        (isVerificationDigit &&
          verificationDigit.toString().trim().length === 0)
      ) {
        return this.setState({
          pageLoad: false,
          isAlert: true,
          alert: {
            type: "danger",
            message: t("validationsMessage.youMustCompleteAllFields"),
          },
        });
      }
      data = {
        ...data,
        endUser: {
          countryId: parseInt(countryId),
          typeDocumentId: parseInt(typeDocument),
          documentNumber,
          isVerificationDigit,
          verificationDigit,
          name,
        },
      };
    }

    data = {
      ...data,
      documentsUpload,
      certificatedId,
      code,
      totalCancellation,
      changeUseBonus: changeUseBonusCheck,
      changeInformationUserEnd: informationUserEndCheck,
      changeinformationTaxpayerSubject: informationTaxpayerSubjectCheck,
      termsAndConditionsCheck,
    };
    projectEmitCertificationUpdate(data).then((response) => {
      if (response.status !== 1) {
        this.setState({
          pageLoad: false,
          isAlert: true,
          alert: {
            type: "danger",
            message: response.codeMessages,
          },
        });
      } else {
        this.setState({
          pageLoad: false,
          isAlert: false,
          showModal: false,
        });
        window.location.reload();
      }
    });
  };

  cleanCheck = () => {
    this.setState({
      changeUseBonusCheck: false,
      informationUserEndCheck: false,
      informationTaxpayerSubjectCheck: false,
    });
  };

  findByKey = () => {
    const { emitcertifications, certificateKey } = this.state
    const emitcertificationsFilteredByKey = emitcertifications.filter((certificate) => certificate.key == certificateKey.replace(/\s+/g, ''))
    if(emitcertificationsFilteredByKey.length <= 0){
      this.setState({
        keyError: "Llave de verificación errónea"
      })
    }else {
      this.setState({
        emitcertificationsFilter: emitcertificationsFilteredByKey,
        keyValidationModal: false,
        certificateKey: ""
      })
    }
  };

  onCloseKeyModal = () => { 
    this.setState({
      keyError: "",
      certificateKey: "",
      keyValidationModal: false
    })
  }

  render() {
    const { t, token, countries } = this.props;
    const {
      emitcertifications,
      emitcertificationsFilter,
      pageLoad,
      page,
      rowsPerPage,
      projectSerialYear,
      serialsElegible,
      showModal,
      isDisabledSaveCancel,
      countryId,
      isPassive,
      passiveSubjectCountryId,
      formatDocumentNumber,
      passiveSubjectFormatDocumentNumber,
      optionsBonus,
      useBonus,
      totalCancellation,
      typeSendCode,
      isAlertConfirm,
      alert,
      changeUseBonus,
      changeUseBonusCheck,
      informationUserEnd,
      informationUserEndCheck,
      informationTaxpayerSubject,
      informationTaxpayerSubjectCheck,
      termsAndConditionsCheck,
      viewDetail,
      keyValidationModal,
      totalCancellationState,
    } = this.state;
    return (
      <div>
        {document.body.classList.add("new-background")}

        <Container
          style={{
            minHeight: "100vh",
            marginTop: token ? "10px" : "150px",
          }}
        >
          {/* Validation modal */}
          <Modal
            show={keyValidationModal}
            centered
            onHide={() => {
              this.onCloseKeyModal();
            }}
          >
            <Modal.Body>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "1.5rem 0 1.5rem 0",
                }}
              >
                <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
                  Validar
                </h2>
                <h6
                  style={{ textTransform: "uppercase", letterSpacing: "2px" }}
                >
                  Certificado
                </h6>
                <p style={{ textAlign: "center", marginTop: "1.5rem" }}>
                  Ingrese la Llave de verificación a buscar
                </p>
                <Form.Control
                  type="text"
                  onChange={(e) => {
                    this.setState({ certificateKey: e.target.value });
                  }}
                />
                {this.state.keyError !== "" && (
                  <p style={{color: "red"}}>{this.state.keyError}</p>
                )}
                <Box sx={{ margin: "10px 0 10px 0" }}>
                  <Button onClick={() =>this.findByKey()} style={{background: "#59b089", borderColor: "inherit"}}>Buscar</Button>
                </Box>
              </Box>
            </Modal.Body>
          </Modal>
          <LoadPage load={pageLoad} />
          <Row>
            <Col
              xs={12}
              sm={12}
              md={6}
              style={{
                alignContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={sectIndicator}
                  alt="Indicator"
                  style={{ marginRight: "0px", width: "18%" }}
                />
                <h2
                  style={{
                    color: "#59B089",
                    fontSize: "2.8rem",
                    fontWeight: "700",
                    lineHeight: "98.5px",
                    textAlign: "left",
                    textTransform: "none",
                  }}
                >
                  {t("Retiros")}
                </h2>
              </div>
            </Col>
            <Col
              md={6}
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Form.Control
                onChange={(event) => {
                  this.handleSearch(event);
                }}
                value={this.state.searchText}
                placeholder={`${t("searchText")}...`}
              />
            </Col>
          </Row>

          <TableContainer
            sx={{ maxHeight: "70vh", borderRadius: "20px" }}
            style={{
              borderRadius: "20px",
              fontFamily: "Montserrat",
              marginTop: "1rem",
              background: "white",
            }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{
                borderRadius: "20px",
                fontFamily: "Montserrat",
                background: "white",
              }}
            >
              <TableHead className="colcx-table" style={{ fontSize: 13 }}>
                <TableRow className="colcx-table">
                  {this.renderSortableHeader("date", `${t("withdrawDate")}`)}
                  {this.renderSortableHeader("projectName", `${t("Proyecto")}`)}
                  {this.renderSortableHeader("year", `${t("year")}`)}
                  {this.renderSortableHeader("serial", `${t("Cod. vintage")}`)}
                  {this.renderSortableHeader(
                    "serialFinal",
                    `${t("Cod. COLCERs retirados")}`
                  )}
                  {this.renderSortableHeader(
                    "reasonUsingCarbonOffsets",
                    `${t("Propósito del Retiro")}`
                  )}

                  <TableCell
                    className="colcx-table-cell-header"
                    style={{ verticalAlign: "middle" }}
                  >
                    {t("userEnd")}
                  </TableCell>
                  <TableCell
                    className="colcx-table-cell-header"
                    style={{ verticalAlign: "middle" }}
                  >
                    {t("passiveSubject")}
                  </TableCell>
                  {this.renderSortableHeader(
                    "quantity",
                    `${t("compensationsDeliveredShortTCO2")}`
                  )}

                  <TableCell
                    className="colcx-table-cell-header"
                    style={{ verticalAlign: "middle" }}
                  >
                    {t("seeCertificate")}
                  </TableCell>
                  {false && (
                    <TableCell
                      className="colcx-table-cell-header"
                      style={{ verticalAlign: "middle" }}
                    >
                      {t("Acciones")}
                    </TableCell>
                  )}

                  {false && (
                    <TableCell
                      className="colcx-table-cell-header"
                      style={{ verticalAlign: "middle" }}
                    >
                      {t("infoProject")}
                    </TableCell>
                  )}
                  {false && (
                    <TableCell
                      className="colcx-table-cell-header"
                      style={{ verticalAlign: "middle" }}
                    ></TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody style={{ fontSize: 12 }}>
                {emitcertificationsFilter
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((certificated, idx) => (
                    <React.Fragment key={idx}>
                      <TableRow
                        key={certificated.id}
                        style={
                          certificated.isCanceled ? { color: "#b0b9c1" } : {}
                        }
                      >
                        <TableCell
                          className="colcx-table-cell-info"
                          style={{ verticalAlign: "middle" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {certificated.date
                              ? certificated.date.split(" ")[0]
                              : ""}
                          </div>
                        </TableCell>
                        {true && (
                          <TableCell
                            className="colcx-table-cell-info"
                            style={{ verticalAlign: "middle" }}
                          >
                            {`${certificated.projectName || "N/A"}`}
                          </TableCell>
                        )}

                        <TableCell
                          className="colcx-table-cell-info"
                          style={{
                            verticalAlign: "middle",
                            textAlign: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {`${certificated.year}`}
                          </div>
                        </TableCell>

                        <TableCell
                          className="colcx-table-cell-info"
                          style={{ verticalAlign: "middle" }}
                        >
                          <div
                            style={{ display: "flex", wordBreak: "break-all" }}
                          >
                            {`${certificated.serial}`}
                          </div>
                        </TableCell>
                        <TableCell
                          className="colcx-table-cell-info"
                          style={{ verticalAlign: "middle" }}
                        >
                          <div
                            style={{ display: "flex", wordBreak: "break-all" }}
                          >
                            {`${certificated.serialFinal}`}
                          </div>
                        </TableCell>
                        <TableCell
                          className="colcx-table-cell-info"
                          style={{ verticalAlign: "middle" }}
                        >
                          {certificated.reasonUsingCarbonOffsets}
                        </TableCell>
                        {certificated.isPublicEndUser === 1 &&
                        certificated.reasonUsingCarbonOffsetsId !== 4 ? (
                          <TableCell
                            className="colcx-table-cell-info"
                            style={{ verticalAlign: "middle" }}
                          >
                            <div className="d-flex flex-column">
                              <span>
                                <strong>Nombre:</strong> {certificated.userEnd}
                              </span>
                              <span>
                                <strong>Identificación:</strong>{" "}
                                {certificated.nitEnd}
                              </span>
                            </div>
                          </TableCell>
                        ) : certificated.auth &&
                          certificated.reasonUsingCarbonOffsetsId !== 4 ? (
                          <TableCell
                            className="colcx-table-cell-info"
                            style={{ verticalAlign: "middle" }}
                          >
                            <div className="d-flex flex-column">
                              <span>
                                <strong>Nombre:</strong> {certificated.userEnd}
                              </span>
                              <span>
                                <strong>Identificación:</strong>{" "}
                                {certificated.nitEnd}
                              </span>
                            </div>
                          </TableCell>
                        ) : certificated.reasonUsingCarbonOffsetsId !== 4 ? (
                          <TableCell
                            className="colcx-table-cell-info"
                            style={{ verticalAlign: "middle" }}
                          >
                            ---
                          </TableCell>
                        ) : (
                          <TableCell
                            className="colcx-table-cell-info"
                            style={{ verticalAlign: "middle" }}
                          >
                            N/A
                          </TableCell>
                        )}
                        <TableCell
                          className="colcx-table-cell-info"
                          style={{ verticalAlign: "middle" }}
                        >
                          {certificated.passiveSubject !== "-" &&
                          certificated.passiveSubjectNit !== "-" ? (
                            <div className="d-flex flex-column">
                              <span>
                                <strong>Nombre:</strong>{" "}
                                {certificated.passiveSubject}
                              </span>
                              <span>
                                <strong>Identificación:</strong>{" "}
                                {certificated.passiveSubjectNit}
                              </span>
                            </div>
                          ) : (
                            "---"
                          )}
                        </TableCell>
                        <TableCell
                          className="colcx-table-cell-info"
                          style={{ verticalAlign: "middle" }}
                        >
                          <NumberFormat
                            value={certificated.quantity}
                            displayType={"text"}
                            thousandSeparator="."
                            decimalSeparator=","
                            isNumericString={false}
                          />
                        </TableCell>
                        <TableCell
                          className="colcx-table-cell-info"
                          style={{ verticalAlign: "middle" }}
                        >
                          <Button
                            size="sm"
                            style={{
                              background: "white",
                              border: "none",
                              borderColor: "inherit",
                            }}
                            title={t("seeCertificate")}
                            onClick={this.onClickDownloadCertificate(
                              certificated.id
                            )}
                            disabled={
                              certificated.isCanceled === 2 ||
                              certificated.isCancelProccess
                                ? true
                                : false
                            }
                          >
                            <img
                              style={{ height: "33px" }}
                              src={certificateIcon}
                            />
                          </Button>
                        </TableCell>

                        {false && (
                          <TableCell
                            className="colcx-table-cell-info"
                            style={{ verticalAlign: "middle" }}
                          >
                            {certificated.consecutive}
                          </TableCell>
                        )}

                        {/* Back to project */}
                        {false &&
                          (certificated.auth ? (
                            <TableCell
                              className="colcx-table-cell-info"
                              style={{ verticalAlign: "middle" }}
                            >
                              <Button
                                size="sm"
                                variant={
                                  certificated.isCanceled ? "secondary" : "info"
                                }
                                title={t("documents")}
                                onClick={
                                  () =>
                                    this.props.history.push(
                                      `/projects/${certificated.projectId}`
                                    )
                                  // this.clickDownloadDocuments(
                                  //   project.id,
                                  //   certificated.id
                                  // )
                                }
                              >
                                <FontAwesomeIcon icon={faPager} />
                              </Button>
                            </TableCell>
                          ) : (
                            false && (
                              <TableCell
                                className="colcx-table-cell-info"
                                style={{ verticalAlign: "middle" }}
                              >
                                ---
                              </TableCell>
                            )
                          ))}
                        {false && (
                          <TableCell
                            className="colcx-table-cell-info"
                            style={
                              certificated.isCanceled
                                ? {
                                    verticalAlign: "middle",
                                    color: "black",
                                  }
                                : { verticalAlign: "middle" }
                            }
                          >
                            {certificated.isKg &&
                              !certificated.isUpdated &&
                              !certificated.isCancelProccess && (
                                <React.Fragment>
                                  <Button
                                    size="sm"
                                    variant="info"
                                    title={t("certificateUpdated.modified")}
                                    onClick={() =>
                                      this.onClickBeforeCertificate(
                                        certificated.id,
                                        idx
                                      )
                                    }
                                    style={{ marginRight: 10 }}
                                  >
                                    <FontAwesomeIcon
                                      icon={
                                        !certificated.showBefore
                                          ? faChevronCircleDown
                                          : faChevronCircleUp
                                      }
                                    />
                                  </Button>
                                </React.Fragment>
                              )}
                            {certificated.operation.isCanceled &&
                            !certificated.isCancelProccess ? (
                              <React.Fragment>
                                <Button
                                  size="sm"
                                  variant="info"
                                  title={t("cancelOrModify")}
                                  onClick={() =>
                                    this.onClickUpdatedCertificate(
                                      certificated.projectId,
                                      certificated.id,
                                      certificated.serial
                                    )
                                  }
                                  disabled={
                                    certificated.isCanceled ? true : false
                                  }
                                  style={{ marginRight: 10 }}
                                >
                                  <FontAwesomeIcon icon={faBan} />
                                </Button>
                                <Helper
                                  text={t(
                                    "certificateHelpers.withdrawalCanceled"
                                  )}
                                />
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {certificated.isCanceled &&
                                  t("certificateUpdated.voided")}
                                {certificated.isCancelProccess && (
                                  <React.Fragment>
                                    <Button
                                      size="sm"
                                      variant="info"
                                      title={t("certificateUpdated.inProgress")}
                                      disabled
                                      style={{ marginRight: 10 }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faHourglassStart}
                                      />
                                    </Button>
                                    <Helper
                                      text={t("certificateHelpers.inProgress")}
                                    />
                                  </React.Fragment>
                                )}

                                {certificated.isUpdated &&
                                  !certificated.isCancelProccess && (
                                    <React.Fragment>
                                      <Button
                                        size="sm"
                                        variant="info"
                                        title={t("certificateUpdated.modified")}
                                        onClick={() =>
                                          this.onClickBeforeCertificate(
                                            certificated.id,
                                            idx
                                          )
                                        }
                                        style={{ marginRight: 10 }}
                                      >
                                        <FontAwesomeIcon
                                          icon={
                                            !certificated.showBefore
                                              ? faChevronCircleDown
                                              : faChevronCircleUp
                                          }
                                        />
                                      </Button>
                                      <Helper
                                        text={t("certificateHelpers.modified")}
                                      />
                                    </React.Fragment>
                                  )}
                              </React.Fragment>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                      {/* ----------------------------------------------------- modified certificate  -------------------------------------------------------*/}
                      {certificated.before &&
                        certificated.showBefore &&
                        certificated.before.map((emit) => (
                          <tr style={{ color: "#b0b9c1" }}>
                            <td style={{ verticalAlign: "middle" }}>
                              <Button
                                size="sm"
                                variant={"secondary"}
                                title={t("seeCertificate")}
                                onClick={() =>
                                  this.onClickGetBeforePDF(
                                    emit.emitCertification.id
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={faPager} />
                              </Button>
                            </td>
                            <td style={{ verticalAlign: "middle" }}>-</td>
                            <td style={{ verticalAlign: "middle" }}>
                              {`${emit.emitCertification.serialInit}`}
                              <br /> a <br />
                              {`${emit.emitCertification.serialEnd}`}
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              {emit.emitCertification.isCanceled ? (
                                <p
                                  style={{ marginTop: 10 }}
                                  class="text-secondary"
                                >
                                  {
                                    emit.emitCertification
                                      .reasonUsingCarbonOffsets
                                  }
                                </p>
                              ) : (
                                emit.emitCertification.reasonUsingCarbonOffsets
                              )}
                            </td>
                            {certificated.projectIsOwner ? (
                              <td style={{ verticalAlign: "middle" }}>
                                {`${emit.emitCertification.nitEnd} - ${emit.emitCertification.userEnd}`}
                              </td>
                            ) : (
                              <td style={{ verticalAlign: "middle" }}>- - -</td>
                            )}
                            <td style={{ verticalAlign: "middle" }}>
                              {`${emit.emitCertification.passiveSubjectNit} - ${emit.emitCertification.passiveSubject}`}
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              {emit.emitCertification.date}
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              <NumberFormat
                                style={{ color: "#6c757d" }}
                                value={emit.emitCertification.quantity}
                                displayType={"text"}
                                thousandSeparator="."
                                decimalSeparator=","
                                isNumericString={false}
                              />
                            </td>
                          </tr>
                        ))}
                      {certificated.beforeKg &&
                        certificated.showBeforeKg &&
                        certificated.beforeKg.map((emit) => (
                          <tr style={{ color: "#b0b9c1" }}>
                            <td style={{ verticalAlign: "middle" }}>
                              <Button
                                size="sm"
                                variant={"secondary"}
                                title={t("seeCertificate")}
                                onClick={this.onClickDownloadCertificateKg(
                                  emit.emitCertification.id
                                )}
                              >
                                <FontAwesomeIcon icon={faPager} />
                              </Button>
                            </td>
                            <td style={{ verticalAlign: "middle" }}>-</td>
                            <td style={{ verticalAlign: "middle" }}>
                              {`${emit.emitCertification.serialInit}`}
                              <br /> a <br />
                              {`${emit.emitCertification.serialEnd}`}
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              {emit.emitCertification.isCanceled ? (
                                <p
                                  style={{ marginTop: 10 }}
                                  class="text-secondary"
                                >
                                  {
                                    emit.emitCertification
                                      .reasonUsingCarbonOffsets
                                  }
                                </p>
                              ) : (
                                emit.emitCertification.reasonUsingCarbonOffsets
                              )}
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              {emit.emitCertification.nitEnd
                                ? `${emit.emitCertification.nitEnd} - ${emit.emitCertification.userEnd}`
                                : `${emit.emitCertification.userEnd}`}
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              {t("NotApplicable")}
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              {emit.emitCertification.date}
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              kg ={" "}
                              <NumberFormat
                                style={{ color: "#6c757d" }}
                                value={emit.emitCertification.quantity}
                                displayType={"text"}
                                thousandSeparator="."
                                decimalSeparator=","
                                isNumericString={false}
                              />
                            </td>
                          </tr>
                        ))}
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={emitcertificationsFilter.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => {
              this.setState({ page: newPage });
            }}
            onRowsPerPageChange={(event) => {
              this.setState({
                rowsPerPage: +event.target.value,
                page: 0,
              });
            }}
          />

          {/* Buttons section */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "2px 0 2px 0",
            }}
          >
            <Button
            style={{background: "rgb(100, 102, 135)", borderColor: "inherit"}}
              onClick={() => {
                this.setState({ keyValidationModal: true, keyError: "" });
              }}
            >
              Validar certificado
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ emitcertificationsFilter: emitcertifications });
              }}
            >
              Limpiar
            </Button>
            <Dropdown style={{ display: "flex", justifyContent: "flex-end" }}>
              <Dropdown.Toggle
                style={platform.buttonSuccess}
                id="dropdown-basic"
              >
                {t("myPosition.download")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    this.onClickDownload("csv", "emitcertifications")
                  }
                >
                  {" "}
                  {t("myPosition.excel")}
                </Dropdown.Item>
                {false && (
                  <>
                    <Dropdown.Item onClick={() => this.onClickDownload("pdf")}>
                      {" "}
                      {t("myPosition.pdf")}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => this.onClickDownload("csv")}>
                      {" "}
                      {t("myPosition.csv")}
                    </Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Box>

          <Modal show={showModal} onHide={this.handleClose} size="lg" centered>
            <Modal.Header closeButton>
              <Modal.Title>{t("cancellationModification")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row class="row">
                <Col>
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      checked={termsAndConditionsCheck}
                      onChange={() =>
                        this.onChangeTerm(termsAndConditionsCheck)
                      }
                      label={t("certificateUpdated.messageNotShare")}
                      style={{
                        fontSize: 14,
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <hr
                style={{
                  color: "#0056b2",
                  borderTop: "1px solid rgb(97, 168, 228)",
                  width: "100%",
                }}
              />
              <h5>
                <Form.Group>
                  <Form.Label>
                    {t("codeMessageSend.part1")}
                    <span
                      onClick={this.resendVerificationCode}
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        fontSize: "14px",
                        textDecoration: "underline",
                        color: "blue",
                      }}
                    >
                      {t("codeMessageSend.part2")}
                    </span>
                    <Form.Check
                      style={{ display: "inline-block", marginLeft: 15 }}
                      type="radio"
                      checked={typeSendCode === "email"}
                      value="email"
                      label="E-MAIL"
                      name="formHorizontalRadios"
                      onChange={this.onChange("typeSendCode")}
                    />
                  </Form.Label>
                  <Form.Control
                    placeholder={t("enterVerificationCode")}
                    type="text"
                    onChange={this.onChange("code")}
                  />
                </Form.Group>
              </h5>
              <hr
                style={{
                  color: "#0056b2",
                  borderTop: "1px solid rgb(97, 168, 228)",
                  width: "100%",
                }}
              />
              {/* --------------------------------------------------total Cancellation----------------------------------------------------------- */}
              {/* {!totalCancellationState && ( */}
              <Fragment>
                <Row>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      checked={totalCancellation}
                      onChange={() =>
                        this.onChangeTotalCancellation(totalCancellation)
                      }
                      disabled={totalCancellationState}
                    />
                  </Col>
                  <Col style={{ position: "absolute", marginLeft: 25 }}>
                    <h5>
                      <Form.Label style={{ marginRight: 10 }}>
                        {t("totalCancellation")}
                      </Form.Label>
                      <Helper
                        text={
                          totalCancellationState
                            ? t("certificateUpdated.serialNot")
                            : t("certificateUpdated.totalCancellation")
                        }
                      />
                    </h5>
                  </Col>
                </Row>
                <hr
                  style={{
                    color: "#0056b2",
                    borderTop: "1px solid rgb(97, 168, 228)",
                    width: "100%",
                  }}
                />
              </Fragment>
              {/* )} */}
              {/* --------------------------------------------------Use Bonus----------------------------------------------------------- */}
              <Fragment>
                <Row>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      checked={changeUseBonusCheck}
                      onChange={() => this.onChangeUseBonus(changeUseBonus)}
                      disabled={totalCancellation ? true : false}
                    />
                  </Col>
                  <Col style={{ position: "absolute", marginLeft: 25 }}>
                    <h5>
                      <Form.Label style={{ marginRight: 10 }}>
                        {t("changeUseBonus")}
                      </Form.Label>
                      <Helper text={t("certificateUpdated.changeUseBonus")} />
                    </h5>
                  </Col>
                </Row>
                <hr style={{ border: "none" }} />
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Select
                      options={optionsBonus}
                      onChange={this.onChangeUseBonu("useBonus")}
                      value={useBonus}
                      label={t("useBonus")}
                      disabled={!changeUseBonusCheck}
                    />
                  </Form.Group>
                </Col>
                <hr
                  style={{
                    color: "#0056b2",
                    borderTop: "1px solid rgb(97, 168, 228)",
                    width: "100%",
                  }}
                />
                <Row>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      checked={informationUserEndCheck}
                      onChange={() =>
                        this.onChangeInformationUserEnd(informationUserEnd)
                      }
                      disabled={totalCancellation ? true : false}
                    />
                  </Col>
                  <Col style={{ position: "absolute", marginLeft: 25 }}>
                    <h5>
                      <Form.Label>{t("changeInformationUserEnd")}</Form.Label>
                    </h5>
                  </Col>
                </Row>
                <hr style={{ border: "none" }} />
                {/* --------------------------------------------------info user end----------------------------------------------------------- */}
                <fieldset>
                  <legend style={{ fontSize: 18 }}>
                    {t("informationUserEnd")}
                  </legend>
                  {countries.length > 0 && (
                    <Row>
                      <Col xs={12} sm={5} md={6} lg={6} xl={4}>
                        <Select
                          options={this.transformLocation(
                            countries.map((e) => ({
                              ...e,
                              data: {
                                formatDocumentNumber: e.formatDocumentNumber,
                                formatCellPhone: e.formatCellPhone,
                              },
                            }))
                          )}
                          label={t("country")}
                          onChange={this.onChangeCountry(
                            "countryId",
                            "formatDocumentNumber"
                          )}
                          value={countryId}
                          disabled={!informationUserEndCheck}
                        />
                      </Col>
                      <DocumentNumber
                        typeDocumentName="typeDocument"
                        documentNumberName="documentNumber"
                        verificationDigitName="verificationDigit"
                        classTypeDocument={{
                          xs: 12,
                          sm: 7,
                          md: 6,
                          lg: 6,
                          xl: 4,
                        }}
                        classDocumentNumber={{
                          style: { marginTop: 10 },
                          xs: 12,
                          sm: 12,
                          lg: 8,
                        }}
                        translation={t}
                        formOnChange={this.onChange}
                        countryId={countryId}
                        formatDocumentNumber={formatDocumentNumber}
                        disabled={!informationUserEndCheck}
                      />
                      <Col xs={10}>
                        <Form.Group>
                          <Form.Label>{t("formRegister.fullname")}</Form.Label>
                          <Form.Control
                            onChange={this.onChange("name")}
                            disabled={!informationUserEndCheck}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                </fieldset>
                <hr style={{ border: "none" }} />
                {/* --------------------------------------------------info passive subject----------------------------------------------------------- */}

                <hr
                  style={{
                    color: "#0056b2",
                    borderTop: "1px solid rgb(97, 168, 228)",
                    width: "100%",
                  }}
                />
                <Row>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      checked={informationTaxpayerSubjectCheck}
                      onChange={() =>
                        this.onChangeInformationTaxpayerSubject(
                          informationTaxpayerSubject
                        )
                      }
                      disabled={
                        totalCancellation || isPassive !== 1 ? true : false
                      }
                    />
                  </Col>
                  <Col style={{ position: "absolute", marginLeft: 25 }}>
                    <h5>
                      <Form.Label style={{ marginRight: 10 }}>
                        {t("changeInformationTaxpayerSubject")}
                      </Form.Label>
                      <Helper text={t("certificateUpdated.changeUseBonus")} />
                    </h5>
                  </Col>
                </Row>
                <hr style={{ border: "none" }} />
                <fieldset>
                  <legend style={{ fontSize: 18 }}>
                    {t("informationTaxpayerSubject")}
                  </legend>
                  {countries.length > 0 && (
                    <Row>
                      <Col xs={12} sm={5} md={6} lg={6} xl={4}>
                        <Select
                          options={this.transformLocation(
                            countries.map((e) => ({
                              ...e,
                              data: {
                                formatDocumentNumber: e.formatDocumentNumber,
                                formatCellPhone: e.formatCellPhone,
                              },
                            }))
                          )}
                          label={t("country")}
                          onChange={this.onChangeCountry(
                            "passiveSubjectCountryId",
                            "passiveSubjectFormatDocumentNumber"
                          )}
                          value={passiveSubjectCountryId}
                          disabled={!informationTaxpayerSubjectCheck}
                        />
                      </Col>
                      <DocumentNumber
                        typeDocumentName="passiveSubjectTypeDocument"
                        documentNumberName="passiveSubjectDocumentNumber"
                        verificationDigitName="passiveSubjectVerificationDigit"
                        classTypeDocument={{
                          xs: 12,
                          sm: 7,
                          md: 6,
                          lg: 6,
                          xl: 4,
                        }}
                        classDocumentNumber={{
                          style: { marginTop: 10 },
                          xs: 12,
                          sm: 12,
                          lg: 8,
                        }}
                        translation={t}
                        formOnChange={this.onChange}
                        countryId={passiveSubjectCountryId}
                        formatDocumentNumber={
                          passiveSubjectFormatDocumentNumber
                        }
                        disabled={!informationTaxpayerSubjectCheck}
                      />
                      <Col xs={10}>
                        <Form.Group>
                          <Form.Label>{t("formRegister.fullname")}</Form.Label>
                          <Form.Control
                            onChange={this.onChange("passiveSubjectName")}
                            disabled={!informationTaxpayerSubjectCheck}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                </fieldset>
              </Fragment>
              {/* --------------------------------------------------format Cancellation or updated ----------------------------------------------------------- */}
              <hr
                style={{
                  color: "#0056b2",
                  borderTop: "1px solid rgb(97, 168, 228)",
                  width: "100%",
                }}
              />
              <Row>
                <Col xs={6} style={{ marginBottom: 10, textAlign: "center" }}>
                  <span
                    style={{
                      color: "-webkit-link",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={() => this.onClickAnnulmentDocumentFormat()}
                  >
                    {t("certificateUpdated.downloadAnnulmentDocument")}
                  </span>
                </Col>
                <Col xs={6} style={{ marginBottom: 10, textAlign: "center" }}>
                  <span
                    style={{
                      color: "-webkit-link",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={() => this.onClickModificationDocumentFormat()}
                  >
                    {t("certificateUpdated.downloadModificationDocument")}
                  </span>
                </Col>
                <Col xs={12} style={{ marginBottom: 10 }}>
                  <FilePond
                    ref={(ref) => (this.pond = ref)}
                    labelIdle={`<span class="filepond--label-action">${t(
                      "certificateUpdated.cancellationOrModification"
                    )}</span>`}
                    files={this.state.documentsWithdrawalCertificate}
                    allowMultiple={true}
                    maxFiles={1}
                    server={serverUploadConfig("/api/file/upload", "projects")}
                    allowImagePreview={false}
                    onprocessfilestart={() =>
                      this.setState({ isDisabledSave: true })
                    }
                    onprocessfile={this.onCompleteFile}
                    onupdatefiles={(fileItems) => {
                      const documentsWithdrawalCertificate = fileItems.map(
                        (fileItem) => fileItem
                      );
                      this.setState({ documentsWithdrawalCertificate });
                    }}
                  />
                </Col>
              </Row>
              <div>
                {isAlertConfirm && (
                  <Error options={alert.message} value={alert.type}></Error>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                {t("buttons.cancel")}
              </Button>
              <Button
                variant="info"
                type="button"
                onClick={this.onSaveModify}
                disabled={isDisabledSaveCancel}
              >
                {t("buttons.accept")}
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

const mapStateToProps = ({ user, general }) => ({
  token: user.token,
  countries: general.countries,
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProjectEmitCertificationGlobalList)
);
