import React, { Component } from "react";
import { Button, Card, Container, Form ,Col,Row} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Error from "../Form/Error";
import LoadPage from "../Load/LoadPage";
import { userPasswordRecovery } from "./../../actions/user";
import { platform } from "./../../Style/index";
import {  Grid } from '@mui/material';
import  DialogComponent from '../../components/Project/Dialog'



class passwordRecovery extends Component {
  constructor(props) {
  document.body.classList.add('new-background');

    super(props);
    this.state = {
      loadPage: false,
      email: "",
      recaptchaValue: "",
      isAlert: false,
      alert: {},
    };
  }
  onChange =
    (name, check = false) =>
      (event) => {
        if (!check) {
          return this.setState({
            [name]: event.target.value,
          });
        }
        return this.setState({
          [name]: event.target.checked,
        });
      };

  onChangeReCAPTCHA = (value) => {
    this.setState({ recaptchaValue: value });
  };

  sendPasswordRecovery = () => {
    this.setState({ loadPage: true });
    userPasswordRecovery(this.state).then((response) => {
      window.grecaptcha.reset();
      const type = response.status !== 1 ? "danger" : "success";
      this.setState({
        isAlert: true,
        loadPage: false,
        modalMessages:false,
        message:"",
        comment:"",
        commentDialog:"",
        alert: {
          type,
          message: response.codeMessages,
          recaptchaValue: false,
        },
      });
    });
  };

  render() {
    const { t } = this.props;
    const { loadPage, email, isAlert, alert, modalMessages,commentDialog,
      message,
      comment, } = this.state;
    return (
      <Container style={{ marginTop: "120px", marginBottom: "167px" }}>
        <LoadPage load={loadPage} />
        <DialogComponent
        t = {t}
        view = {modalMessages || isAlert}
        alert={isAlert}
        alertMessage={alert && alert.message && alert.message}
        alertType={alert && alert.type && alert.type}
        tittle ={t("confirmation")}
        message={message}
        comment={comment}
        commentDialog={commentDialog}
        setComment={(event)=>this.setState({commentDialog:event})}
        buttons={[
            {value:`${t("buttons.accept")}`,onclick:()=>{
                  this.setState({pageLoad:false,
                    isAlert:false
                })}}

        ]}
        />
        
        <Row style={{marginTop:"180px"}}>
            {/* <Grid container spacing={1} style={{display:"flex", marginTop:"20px"}}>
            <Col xs={12} sm={12} md={12} style={{alignContent:"center", alignItems: "center", textAlign: "center"}}>
            <h2 style={{color:"#fff"}}>{t("PasswordRecovery")}</h2>
        
        </Col>
        </Grid> */}
       
        <Card
          className="custom-card custom-card-height"
          style={{ margin: "0 auto", maxWidth: "700px", }}
        >
          <Card.Header className="card-custom-header">
            <legend className="card-header-title">{t("changePassword")}</legend>
          </Card.Header>
          <Card.Body>
            
            <Form>
              <Form.Group>
              
                  <Form.Label>{t("Ingrese el correo electrónico que registró en la plataforma. Le llegará un mensaje a su correo con los pasos a seguir para la recuperación de su contraseña")}
                  </Form.Label>
                <Form.Label>{t("formRegister.email")}</Form.Label>
                <Form.Control
                  type="email"
                  isInvalid={email.length ? false : true}
                  placeholder={t("formRegister.email")}
                  value={email}
                  onChange={this.onChange("email")}
                />
              </Form.Group>
              <Form.Group style={{ display: 'flex', justifyContent: 'center' }}>
              <ReCAPTCHA
                sitekey="6LefRPYdAAAAANdQTSZ4NA-OV2BmT3dojawQ8-Gf"
                onChange={this.onChangeReCAPTCHA}
              />
              {/* {isAlert && (
                <Error options={alert.message} value={alert.type}></Error>
              )} */}
              </Form.Group>
              <Form.Group style={{ marginTop: "10px", display: 'flex', justifyContent: "center" }}>
                <Button
                  style={platform.buttonSuccess}
                  type="button"
                  onClick={this.sendPasswordRecovery}
                >
                  {t("RecoverPassword")}
                </Button>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
        </Row>
      </Container>
    );
  }
}

export default withTranslation()(connect()(passwordRecovery));
