import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FilePond } from "react-filepond";
import { config as serverUploadConfig } from "../../helpers/serverUpload";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Button} from "react-bootstrap";
import DialogComponent from "../../components/Project/Dialog";
import { platform } from "./../../Style/index";
import actionGlobalConsultation from "../../actions/gobalConsultation";
import { inputsComentsForm } from "../../constants/constantsGlobalConsultation";
import CustomTextFild from "../custom/CustomTextFied";
import validationUtils from "../../js/validators";
import CustomButton from "../custom/CustomButton";
import CustomAlert from "../custom/CustomAlert";
import CustomLoader from "../custom/CustomLoader";

import fileUpload from "./../../images/file-upload.png";

function CommentsForm({ idProject, stateComments }) {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
  });

  const [view, setView] = useState(false)
  const [alert, setAlert] = useState("danger")
  const [errors, setErrors] = useState({
    name: false,
    email: false,
  });
  const [allNews, setAllNews] = useState([]);
  const [document, setDcument] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);
  const { t } = useTranslation();

  const actualizarEtiquetas = (etiquetas) => {
    return etiquetas.map((etiqueta) => {
      const nuevaEtiqueta = t(
        `globalConsultation.initiatives.labelsInputs.${etiqueta.name.toLowerCase()}`
      );
      return { ...etiqueta, label: nuevaEtiqueta };
    });
  };

  const nuevasEtiquetas = actualizarEtiquetas(inputsComentsForm);

  const resetValues = () => {
    setFormValues({
      name: "",
      email: "",
    });
    setErrors({
      name: false,
      email: false,
    });
    setDcument([]);
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const sendComment = async () => {
    let fileInformation = {};

    if (document && document.length > 0) {
      if (
        Object.keys(document[0].getMetadata()).length > 0 &&
        document[0].getMetadata().name
      ) {
        fileInformation = document[0].getMetadata();
      } else {
        fileInformation = JSON.parse(document[0].serverId);
      }
    }

    const data = {
      project_id: idProject,
      name: formValues.name,
      document_number: formValues.document_number,
      email: formValues.email,
      company: formValues.company,
      message: formValues.message,
      is_document: Object.keys(fileInformation).length > 0 ? 1 : 0,
      document_name:
        Object.keys(fileInformation).length > 0 ? fileInformation.name : "",
      document: Object.keys(fileInformation).length > 0 ? fileInformation : "",
    };

    const response =
      await actionGlobalConsultation.postGlobalConsultationCommetn(data);

    if(response.status) {
      setView(response.status === 200 ? true : false)
      setAlert(response.status === 200 ? "sucess" : "danger")
    }

    
    return response;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const validationResult = validationUtils.validateCommentsForm(
        formValues,
        setErrors
      );

      if (validationResult.state !== true) {
        setOpenLoader(true);
        const request = await sendComment();

        if (request.status === 200) {
          stateComments(request.response);
          setOpenLoader(false);
          resetValues();
        } else if (request.status === 500) {
          setOpenLoader(false);
          setAllNews([
            {
              name: "document",
              message: `El documento con el nombre "${request.message}" ya está registrado. Por favor, cambie el nombre o seleccione otro documento`,
            },
          ]);
        }
      } else {
        const news = validationResult.news;
        setAllNews(news);
        const updatedErrors = { ...errors };

        // Establecer todas las claves a false
        Object.keys(updatedErrors).forEach((key) => {
          updatedErrors[key] = false;
        });

        // Iterar sobre las noticias y actualizar las claves correspondientes
        news.forEach((notification) => {
          updatedErrors[notification.name] = true;
        });

        setErrors(updatedErrors);
      }
    } catch (error) {
      console.error("Error al manejar el formulario:", error);
      // Puedes manejar el error de alguna manera, por ejemplo, mostrar un mensaje al usuario.
    }
  };

  const handleFilePondError = (error) => {
    setAllNews([
      {
        name: "archivo",
        message:
          "El archivo seleccionado ya está registrado. Por favor, seleccione otro archivo o cambie el nombre del archivo y vuelva a intentarlo.",
      },
    ]);
  };

  return (
    <>
      <DialogComponent
        t={t}
        view={view}
        alert={true}
        alertMessage={"El registro de su comentario ha sido exitoso."}
        alertType={"success"}
        buttons={[
          {
            value: `${t("buttons.accept")}`,
            onclick: () => {
              setView(false)
            },
          },
        ]}
      />
      <form onSubmit={handleSubmit}>
        <Paper
          elevation={4}
          style={{ width: "100%", padding: 40, marginBottom: 40 }}
        >
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="start"
            alignItems="center"
            alignContent="center"
            wrap="wrap"
          >
            {nuevasEtiquetas.map((input) => (
              <Grid item md={input.column} key={input.id}>
                <CustomTextFild
                  label={`${input.label}`+ ( input.required ? "*" : "")}
                  name={input.name}
                  type={input.type}
                  value={formValues[input.name]}
                  change={handleChange}
                  fullWidth={input.fullWidth}
                  multiline={input.multiline}
                  rows={input.rows}
                  error={input.required ? errors[input.name] : false}
                />
              </Grid>
            ))}

            <Grid item md={12}>
              <Typography
                variant="body1"
                color="initial"
                style={{ color: "rgba(0, 0, 0, 0.6)", marginBottom: 10 }}
              >
                {t("filePond.project.documentSelect")}
              </Typography>
              <FilePond
               style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
                labelIdle={`<div style="display:flex, align-items:center">
                                          <img src="${fileUpload}" style="width: 3rem; height: 3rem; cursor: pointer;"/>
                                        </div>`}
                files={document}
                allowMultiple={false}
                maxFiles={1}
                server={serverUploadConfig(
                  "/api/file/upload",
                  "generalconsultation"
                )}
                allowImagePreview={true}
                onupdatefiles={(fileItems) => setDcument(fileItems)}
                onerror={(error) => handleFilePondError(error)}
              />
            </Grid>

            <Grid
              item
              md={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

              }}
            >
              <Button
              type={"submit"}
                        style={{
                          ...platform.buttonSuccess,
                          marginTop: "0.5rem",
                        }}>{t("buttons.send")}</Button>
              {/* <CustomButton
                type={"submit"}
                style={"green"}
                text={t("buttons.send")}
              /> */}
            </Grid>
          </Grid>
          <CustomAlert info={allNews} variant={"error"} />
          <CustomLoader open={openLoader} setOpen={setOpenLoader} />
        </Paper>
      </form>
    </>
  );
}

export default CommentsForm;
