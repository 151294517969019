import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

import CommentsForm from "./commentsForm";
import AllComments from "./allComments";
import TableConsultation from "./TableConsultation";
import CustomTextFild from "../custom/CustomTextFied";
import actionGlobalConsultation from "../../actions/gobalConsultation";
import CustomLoader from "../custom/CustomLoader";

// Alert
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

function Initiatives(props) {
  const [comments, setComments] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);
  const receivedState = props.location.state;
  const [token, setToken] = useState(false);

  //snackbar states
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const { t } = useTranslation();

  useEffect(() => {
    const token = localStorage.getItem("KarbonoaTOKEN");
    const fetchData = async () => {
      try {
        const allComments = await getAllComments();
        setComments(allComments.response);
      } catch (error) {
        console.error("Error al obtener comentarios:", error);
      }
    };
    setToken(token ? true : false);

    fetchData();
  }, []);

  const getAllComments = async () => {
    try {
      const allComments = [];
      //await actionGlobalConsultation.getAllComments({ project_id: receivedState.project_id });

      return allComments;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const downloadDocument = async (info) => {
    setOpenLoader(true);
    const data = { project_id: info.project_id };
    const result = await actionGlobalConsultation.getDownloadDocument(data);

    if (result.status === 1) {
      window.open(result.url);
      setOpenLoader(false);
    
    } else {
      // Internal error
      setOpen(true);
      setOpenLoader(false);
    }
  };

  const headers = [
    {
      id: "doc",
      label: `${t("globalConsultation.initiatives.labelDocument")}`,
    },
    {
      id: "action",
      label: `${t("globalConsultation.initiatives.labelDownload")}`,
    },
  ];

  const rowsActive = [
    {
      id: 0,
      name: `${t("globalConsultation.initiatives.DDPDownload")}`,
      action: (
        <IconButton
          color="success"
          onClick={() => downloadDocument(receivedState)}
        >
          <DownloadForOfflineIcon />
        </IconButton>
      ),
    },
    {
      id: 1,
      name: `${t("globalConsultation.initiatives.commentsFormat")}`,
      action: (
        <IconButton
          color="success"
          onClick={() => window.open('https://ecoregistrystoragedev.blob.core.windows.net/generalconsultation/formato_de_comentarios.docx', '_blank')}
        >
          <DownloadForOfflineIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Container maxWidth="lg" style={{ marginTop: token ? 0 : 20 }}>
      {document.body.classList.add("new-background")}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          No existe documento para descargar!
        </Alert>
      </Snackbar>
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
      >
        {!token && (
          <>
            <Grid item md={12} style={{ marginTop: token ? 0 : "7rem" }}>
              <Typography variant="h3" color="initial" align="center">
                {""}
              </Typography>
            </Grid>
            <Grid item md={12}>
              {""}
            </Grid>
            </>
        )}
            <Grid item md={12} style={{ marginTop: 40 }}>
              <h4 className="main-font-style" align="left">
                {t("globalConsultation.initiatives.initiative")}
              </h4>
            </Grid>
          

        <Grid item md={4} style={{ marginBottom: 40 }}>
          <CustomTextFild
            label={t("globalConsultation.initiatives.code")}
            name={"code"}
            type={"text"}
            value={receivedState.project_code}
            change={(e) => console.log(e.target.value)}
            fullWidth={true}
            error={false}
          />
        </Grid>
        <Grid item md={4} style={{ marginBottom: 40 }}>
          <CustomTextFild
            label={t("globalConsultation.initiatives.name")}
            name={"name"}
            type={"text"}
            value={receivedState.project_name}
            change={(e) => console.log(e.target.value)}
            fullWidth={true}
            error={false}
          />
        </Grid>
        <Grid item md={4} style={{ marginBottom: 40 }}>
          <CustomTextFild
            label={t("globalConsultation.initiatives.owner")}
            name={"company"}
            type={"text"}
            value={receivedState.owner_name}
            change={(e) => console.log(e.target.value)}
            fullWidth={true}
            error={false}
          />
        </Grid>
        <Grid item md={12}>
          <Divider />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={0}
        direction="row"
        justifyContent="start"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
      >
        {true && (
          <>
            {" "}
            <Grid item md={12} style={{ marginTop: 40, marginBottom: 10 }}>
              <h4 className="main-font-style" align="left">
                {t("globalConsultation.initiatives.document")}
              </h4>
            </Grid>
            <Grid item md={12}>
              <TableConsultation columns={headers} rows={rowsActive} />
            </Grid>
            <Grid item md={12} style={{ marginTop: 40, marginBottom: 40 }}>
              <Divider />
            </Grid>
          </>
        )}

        {/* <Grid item md={12} style={{ marginBottom: 40 }}>
                    <Typography variant="h4" color="initial" align="left">{t("globalConsultation.initiatives.comments")}</Typography>
                </Grid>

                <Grid item md={12} style={{ marginBottom: 40 }}>
                    <AllComments comments={comments} stateComments={setComments}/>
                </Grid> */}

        <Grid item md={12} style={{ marginBottom: 10 }}>
          <h4 className="main-font-style" align="left">
            {t("globalConsultation.initiatives.contact")}
          </h4>
        </Grid>
      </Grid>

      <CommentsForm
        idProject={receivedState.project_id}
        stateComments={setComments}
      />
      <CustomLoader open={openLoader} setOpen={setOpenLoader} />
    </Container>
  );
}

export default Initiatives;
