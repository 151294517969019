import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Divider from "@mui/material/Divider";

import TableConsultation from "./TableConsultation";
import CustomImputSearch from "../custom/CustomImputSearch";
import actionGlobalConsultation from "../../actions/gobalConsultation";
import sectIndicator from "../../images/sect_indicator.svg";

function GlobalConsultation({ token }) {
  const [loading, setLoading] = useState(false);
  const [rowsActive, setRowsActives] = useState([]);
  const [rowsInactive, setRowsInactive] = useState([]);
  const [filterConsultation, setFilterConsultation] = useState("");
  const [filterConsultationClose, setFilterConsultationClose] = useState("");

  const { t } = useTranslation();

  const headersActive = [
    { id: "code", label: `${t("globalConsultation.activeTableLabels.cod")}` },
    { id: "name", label: `${t("globalConsultation.activeTableLabels.name")}` },
    {
      id: "start",
      label: `${t("globalConsultation.activeTableLabels.dateStart")}`,
    },
    {
      id: "end",
      label: `${t("globalConsultation.activeTableLabels.dateFinalization")}`,
    },
    {
      id: "comments",
      label: `${t("globalConsultation.activeTableLabels.comments")}`,
    },
  ];

  const headersEnd = [
    { id: "code", label: `${t("globalConsultation.activeTableLabels.cod")}` },
    { id: "name", label: `${t("globalConsultation.activeTableLabels.name")}` },
    {
      id: "start",
      label: `${t("globalConsultation.activeTableLabels.dateStart")}`,
    },
    {
      id: "end",
      label: `${t("globalConsultation.activeTableLabels.dateFinalization")}`,
    },
  ];

  const getAllGlobalConsultation = async () => {
    try {
      setLoading(true);
      const result = await actionGlobalConsultation.getAll();
      if (result.status === 200) {
        setLoading(false);
        setRowsActives(result.response.active);
        setRowsInactive(result.response.inactive);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllGlobalConsultation();
  }, []);

  return (
    <>
      {document.body.classList.add("new-background")}
      <Container style={{ maxWidth: "95%" }}>
        <Grid
          container
          spacing={1}
          flexWrap={"nowrap"}
          direction="column"
        >
          <Grid item md={12} xs={12} sm={12} >
            <Box
              sx={{justifyContent: {sm: "center", xs: "center", md: "flex-start"}}}
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: token ? 0 : "10%",
              }}
            >
              <img
                src={sectIndicator}
                alt="Indicator"
                style={{
                  marginRight: "20px",
                  position: "absolute",
                  left: token ? "1.5rem": "-3.5rem",
                }}
              />
              <h4
                style={{
                  color: "#59B089",
                  fontSize: "2rem",
                  fontWeight: "700",
                  lineHeight: "98.5px",
                  textAlign: "left",
                  textTransform: "none",
                }}
              >
                {t("globalConsultation.actives")}
              </h4>
            </Box>
          </Grid>

          <Grid
            item
            md={12}
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "left",
            }}
          >
            <CustomImputSearch
              setFilter={(filter) => setFilterConsultation(filter)}
            />
          </Grid>

          <Grid item md={12} style={{ marginTop: 20, marginBottom: 40 }}>
            {loading ? (
              <LinearProgress color="success" />
            ) : (
              <TableConsultation
                columns={headersActive}
                rows={rowsActive}
                action={true}
                filter={filterConsultation}
              />
            )}
          </Grid>

          <Grid item md={12}>
            <Divider />
          </Grid>

          <Grid item md={12}>
          <Box
              sx={{justifyContent: {sm: "center", xs: "center", md: "flex-start"}}}
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: token ? 0 : "3%",
              }}
            >
              <img
                src={sectIndicator}
                alt="Indicator"
                style={{
                  marginRight: "20px",
                  position: "absolute",
                  left: token ? "1.5rem": "-3.5rem",
                }}
              />
              <h4
                style={{
                  color: "#59B089",
                  fontSize: "2rem",
                  fontWeight: "700",
                  lineHeight: "98.5px",
                  textAlign: "left",
                  textTransform: "none",
                }}
              >
                {t("globalConsultation.finished")}
              </h4>
            </Box>
          </Grid>

          <Grid
            item
            md={12}
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "left",
            }}
          >
            <CustomImputSearch
              setFilter={(filter) => setFilterConsultationClose(filter)}
            />
          </Grid>

          <Grid item md={12} style={{ marginTop: 20, marginBottom: 40 }}>
            {loading ? (
              <LinearProgress color="success" />
            ) : (
              <TableConsultation
                columns={headersEnd}
                rows={rowsInactive}
                action={false}
                filter={filterConsultationClose}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default GlobalConsultation;
