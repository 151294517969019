import React, { Component } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import Error from "../Form/Error";
import { setPassword } from "./../../actions/user";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAlert: false,
      alert: {},
      password: "",
      newPassword: "",
      recaptchaValue:'',
      ConfPassword: "",
      valid: {
        password: false,
        newPassword: false,
        ConfPassword: false,
      },
    };
  }

  onChange =
    (name, check = false) =>
    (event) => {
      if (!check) {
        return this.setState({
          [name]: event.target.value,
        });
      }
      return this.setState({
        [name]: event.target.checked,
      });
    };

  sendLogin = () => {
    let valid = {};
    Object.keys(this.state).map(
      (property) =>
        (valid[property] = this.state[property].length === 0 ? true : false)
    );
    this.setState({ valid });
    const { newPassword, ConfPassword } = this.state;
    if (newPassword !== ConfPassword) {
      return this.setState({
        isAlert: true,
        alert: {
          type: "danger",
          message: "Las contraseñas no coinciden",
        },
      });
    }
    if (this.formValid()) {
      setPassword(this.state).then((response) => {
        const typeAlert = response.status !== 1 ? "danger" : "success";
        this.setState({
          isAlert: true,
          alert: {
            type: typeAlert,
            message: response.codeMessages,
          },
        });
        if (typeAlert === "success") {
          this.setState({ password: "", newPassword: "", ConfPassword: "" });
        }
      });
    }
  };

  formValid = () => {
    const properties = Object.keys(this.state);
    for (let i = 0; i < properties.length; i++) {
      if (this.state[properties[i]].length === 0) {
        return false;
      }
    }
    return true;
  };
  onChangeReCAPTCHA = (value) => {
    this.setState({ recaptchaValue: value });
  };

  render() {
    const { t } = this.props;
    const passwordConditions = t("passwordConditions.conditions", {
      returnObjects: true,
    });
    const { isAlert, alert, password, newPassword, ConfPassword, valid } =
      this.state;
    return (
      <Container style={{ marginBottom: "1rem" }}>
        <Card
          className="custom-card custom-card-height"
          style={{ margin: "0 auto", maxWidth: "700px" }}
        >
          <Card.Header className="card-custom-header">
            <legend className="card-header-title">{t("changePassword")}</legend>
          </Card.Header>
          <Card.Body>
            <Form>
              <Row>
                <Col xs={12} md={12}>
                  <Form.Group>
                  <Form.Label>{t("Ingrese el correo electrónico que registró en la plataforma. Le llegará un mensaje a su correo con los pasos a seguir para la recuperación de su contraseña")}
                  </Form.Label>
                    <Form.Label>{t("currentPassword")}</Form.Label>
                    <Form.Control
                      isInvalid={valid.password}
                      type="password"
                      placeholder={t("currentPassword")}
                      onChange={this.onChange("password")}
                      value={password}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{t("newPassword")}</Form.Label>
                    <Form.Control
                      type="password"
                      isInvalid={valid.newPassword}
                      placeholder={t("newPassword")}
                      onChange={this.onChange("newPassword")}
                      value={newPassword}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{t("confirmPassword")}</Form.Label>
                    <Form.Control
                      isInvalid={valid.ConfPassword}
                      type="password"
                      placeholder={t("confirmPassword")}
                      onChange={this.onChange("ConfPassword")}
                      value={ConfPassword}
                    />
                  </Form.Group>
                 
                </Col>
                <Col xs={12} md={12}>
                  <p>{t("passwordConditions.title")}</p>
                  <ul>
                    {typeof passwordConditions === "object" &&
                      passwordConditions.map((e, idx) => (
                        <li key={idx}>{e}</li>
                      ))}
                  </ul>
                </Col>
                <Col xs={12} md={12}>
                  {isAlert && (
                    <Error options={alert.message} value={alert.type}></Error>
                  )}
                </Col>
                <Col xs={12} md={12}>
                
              <ReCAPTCHA
                style={{ display: "flex", justifyContent: "center", marginTop: "2rem"}}
                sitekey="6LefRPYdAAAAANdQTSZ4NA-OV2BmT3dojawQ8-Gf"
                onChange={this.onChangeReCAPTCHA}
              />
             
                <Form.Group
                    style={{ marginTop: "10px" }}
                    className="text-center"
                  >
                    <Button
                      variant="info"
                      style={{ background: "#59B089" }}
                      type="button"
                      onClick={this.sendLogin}
                    >
                      {t("buttons.save")}
                    </Button>
                  </Form.Group>
                  </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}

export default withTranslation()(ChangePassword);
