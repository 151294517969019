import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";


const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <Container style={{height: "100vh"}}>
      
    </Container>
  );
};

export default AboutUs;
