import { projectCompanySerial } from "../api/projectCompanySerial";

const actionProjectCompanySerial = {
  
  getTransactions: () =>
    projectCompanySerial.getTransactions().then((response) => response),
  projectUnblocked: (data) =>
    projectCompanySerial.projectUnblocked(data).then((response) => response),
  
  confirmTransaccion: (data) =>
    projectCompanySerial.confirmTransaccion(data).then((response) => response),
  cancelTransaction: (data) =>
    projectCompanySerial.cancelTransaction(data).then((response) => response),
  getProjects: () =>
    projectCompanySerial.getProjects().then((response) => response),
  getProjectsVintage: () =>
    projectCompanySerial.getProjectsVintage().then((response) => response),
  getCompanies: () =>
    projectCompanySerial.getCompanies().then((response) => response),

  getReleasesQtyByCompany: (id) =>
    projectCompanySerial.getReleasesQtyByCompany(id).then((response) => response),

  getSerials: (projectId) =>
    projectCompanySerial.getSerials(projectId).then((response) => response),
  getSerial: (projectId, id) =>
    projectCompanySerial.getSerial(projectId, id).then((response) => response),
  getValidationSerial: (serial) =>
    projectCompanySerial
      .getValidationSerial(serial)
      .then((response) => response),
  transferExchange: (data) =>
    projectCompanySerial.transferExchange(data).then((response) => response),
  formatDownload: (data) =>
    projectCompanySerial.formatDownload(data).then((response) => response),
    formatDownloadTransferred: (data) =>
    projectCompanySerial.formatDownloadTransferred(data).then((response) => response),
    formatGeneralDownload: (data) =>
    projectCompanySerial.formatGeneralDownload(data).then((response) => response),
};


export default actionProjectCompanySerial;
