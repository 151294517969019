import { GET, POST } from ".";
export const projectCompanySerial = {
  getProjects: () => GET(`/api/projectCompanySerial/projects`),
  getCompanies: () => GET(`/api/projectCompanySerial/getCompanies`),
  getReleasesQtyByCompany: (id) =>
    GET(`/api/projectCompanySerial/getReleasesQtyByCompany/${id}`),
  getTransactions: () => GET(`/api/projectCompanySerial/getTransactions`),
  projectUnblocked: (data) =>
    POST(`/api/projectCompanySerial/projectUnblocked`, data),
  cancelTransaction: (data) =>
    POST(`/api/projectCompanySerial/cancelTransaction`, data),
  confirmTransaccion: (data) =>
    POST(`/api/projectCompanySerial/confirmTransaccion`, data),
  getProjectsVintage: () => GET(`/api/projectCompanySerial/projectsVintage`),
  getSerial: (projectId, id) =>
    GET(`/api/projectCompanySerial/projects/${projectId}/serials/${id}`),
  getValidationSerial: (serial) =>
    GET(`/api/projectCompanySerial/getValidationSerial/${serial}`),
  formatDownload: (data) =>
    POST(`/api/projectCompanySerial/formatDownload`, data),
  formatDownloadTransferred: (data) =>
    POST(`/api/projectCompanySerial/formatDownloadTransferred`, data),
  formatGeneralDownload: (data) =>
    POST(`/api/projectCompanySerial/publicInformation`, data),
};
