import React, { Fragment, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
import jwt_decode from "jwt-decode";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import logo from "../../images/imagen_2024-11-11_235355051.svg";
import DialogComponent from "../Project/Dialog";
//import logo from "../../images/logo-colcx-white-2.svg";

import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Footer from "../Footer/Footer";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import IconNotifications from "../Notifications/IconNotification";
import Select from "../Form/Select";
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import StarBorder from "@mui/icons-material/StarBorder";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import AssessmentIcon from "@mui/icons-material/Assessment";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CircleIcon from "@mui/icons-material/Circle";

// Sidebar icons
import homeIcon from "./../../images/home-icon.svg";
import myColcersIcon from "./../../images/my-colcers-icon.svg";
import myProjectsIcon from "./../../images/my-projects-icon.svg";
import worldIcon from "./../../images/world-icon.svg";

import Navbar from "./Navbar";
import { links, myTrading, myAccount } from "../../conf";

const drawerWidth = 380;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const transformSelect = (data) => {
  return data
    ? data.map((select) => ({
        value: select.company_id,
        label: select.company_company_id || select.description,
        data: select,
      }))
    : [];
};

export default function HeaderTwo(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [displayEnglishMaintenance, setDisplayEnglishMaintenance] = React.useState(false)
  const [openItems, setOpenItems] = useState({});
  const [  isHidden, setIsHidden]=useState(false )
  const language = window.localStorage.getItem("i18nextLng") || "es";
  const [languageDefault, setLanguageDefault] = React.useState(
    language || "es"
  );

  const { t } = useTranslation();

  const infoUsers = jwt_decode(props.token);

  const changeLanguage = (lng, reload = true) => {
    const i18n = props.i18n;
    i18n.changeLanguage(lng);
    if (reload) {
      window.location.reload();
    }
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    open && setOpen(false);
  };

  const [tradingOpen, setTradingOpen] = React.useState(false);

  const handleTradingClick = () => {
    setTradingOpen(!tradingOpen);
  };

  const handleToggle = (name) => {
    setOpenItems((prevOpenItems) => ({
      ...prevOpenItems,
      [name]: !prevOpenItems[name],
    }));
  };

  const renderNav = (token) => {
    const nav = myAccount(token);
    const infoUser = jwt_decode(token);

    // Add public information
    const navCopy = [...nav];
    navCopy.push({
      name: "publicInformation",
      key: "project",
      order: 3,
      links: [
        {
          to: "/projects",
          label: "sideNav.allProjects",
        },
        {
          to: "/global-consultation",
          label: "sideNav.globalConsultation",
        },
        {
          to: "/vintages",
          label: "sideNav.vintages",
        },
        {
          to: "/withdraw",
          label: "sideNav.withdrawal",
        },
        {
          to: "/about-us",
          label: "sideNav.about",
        }

      ],
    });
    // Custom sidebar names
    const sidebar = navCopy.map((item) => {
      switch (item.name) {
        case "projects":
          return { ...item, name: "sideProject" };
        case "positions":
          return { ...item, name: "sidePosition" };
        default:
          return item;
      }
    });

    return sidebar
      .filter((e) => e.name !== "myAccount")
      .map((e, index) => (
        <ListItem
          onClick={handleDrawerOpen}
          disablePadding
          sx={{
            display: "block",
            marginBottom: open ? 5 : 1,
            background: "white",
           
          }}
          key={e.name}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
              boxShadow: "0px 4px 4px 0px #00000040",
              borderRadius: "39px",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                color: "white",
              }}
            >
              {e.name === "sideProject" ? (
                <img src={myProjectsIcon} />
              ) : e.name === "sidePosition" || e.name === "transactions" ? (
                <img src={myColcersIcon} />
              ) : (
                <img src={worldIcon} />
              )}
            </ListItemIcon>
            <ListItemText
              primary={
                <p className="main-font" style={{ fontWeight: 700, margin: 0 }}>
                  {t(`${e.name}`)}
                </p>
              }
              sx={{
                opacity: open ? 1 : 0,
                color: "#59B089",
                fontWeight: "700",
               
              }}
            />
          </ListItemButton>

          {(e.name === "positions" || e.name === "sidePosition") &&
            infoUser.typeAccount !== "certifier" && (
              <RouterLink
                to="/my-positions"
                key="/my-positions"
                style={{ textDecoration: "none" }}
              >
                <Collapse
                  in={open}
                  timeout="auto"
                  unmountOnExit
                  onClick={handleDrawerClose}
                >
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText
                        primary={
                          <p className="main-font" style={{ margin: 0 }}>
                            {t("sideNav.myPositions")}
                          </p>
                        }
                        style={{ color: "black", textDecoration: "none" }}
                      />
                    </ListItemButton>
                  </List>
                </Collapse>
              </RouterLink>
            )}

          {e.links &&
            e.links.map((nav) => (
              <RouterLink
                to={nav.to}
                key={nav.to}
                style={{ textDecoration: "none" }}
              >
                <Collapse
                  in={open}
                  timeout="auto"
                  unmountOnExit
                  onClick={handleDrawerClose}
                >
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText
                        primary={
                          <p className="main-font" style={{ margin: 0 }}>
                            {t(`${nav.label}`)}
                          </p>
                        }
                        style={{ color: "black", textDecoration: "none" }}
                      />
                    </ListItemButton>
                  </List>
                </Collapse>
              </RouterLink>
            ))}
        </ListItem>
      ));
  };

  return (
    <div>
      <Box sx={{ display: "flex", minHeight: "100vh" }}>

          <DialogComponent
                      t={t}
                      view={displayEnglishMaintenance}
                      alert={true}
                      alertMessage="Soon you will be able to consult all the information on the site in English."
                      alertType={"success"}
                      buttons={[
                        {
                          value: `${t("buttons.accept")}`,
                          onclick: () => {
                            setDisplayEnglishMaintenance(false)
                          },
                        },
                      ]}
                    />

        <AppBar
          position="fixed"
          open={open}
          sx={{ background: "white", zIndex: 1 }}
          elevation={0}
        >
          <Grid container spacing={0}>
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  background: "white",
                  height: "100%",
                  width: "65px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              ></div>
            </Grid>

            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {false && (
                <Select
                  // styles={true}
                  options={transformSelect(infoUsers.userCompanies)}
                  // onChange={this.onChange("company")}
                  // value={company}
                />
              )}
            </Grid>

            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></Grid>

            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {false && <IconNotifications total={3} />}
              {/* <ReactFlagsSelect
                defaultCountry={
                  languageDefault === "en"
                    ? "US"
                    : languageDefault === "es"
                    ? "ES"
                    : "ES"
                }
                placeholder="..."
                style={{ color: "white", display: "inline" }}
                countries={["US", "ES"]}
                onSelect={(e) => {
                  setLanguageDefault(
                    e === "US" || e === "en"
                      ? "en"
                      : e === "es" || e === "EN"
                      ? "es"
                      : "es"
                  );
                  changeLanguage(
                    e === "US" || e === "en"
                      ? "en"
                      : e === "es" || e === "EN"
                      ? "es"
                      : "es"
                  );
                }}
                customLabels={{ US: "en", ES: "es" }}
              /> */}
              <div
            style={{
              display: isHidden ? "none" : "flex",
              justifyContent: "flex-end",
              width: "100%",
              paddingRight: "8%",
            }}
          >
           
            <button
              style={{
                background: "#3E7A5F",
                cursor: "pointer",
                border: "none",
                color: "white",
                padding: "5px 7px 5px 7px",
                gap: "7px",
                borderRadius: "9px 0px 0px 0px",
                opacity: "0px",
                outline: "none"
              }}
              onClick={() => {
                setLanguageDefault("es")
                changeLanguage("es")
              }}
              
            >
              <img src={worldIcon} style={{ marginRight: "0.5rem" }} />
              ESP
            </button>

        
            <button
              style={{
                cursor: "pointer",
                background: "#3E7A5F",
                outline: "none",
                border: "none",
                color: "white",
                padding: "5px 7px 5px 7px",
                gap: "7px",
                borderRadius: "0px 9px 0px 0px",
                opacity: "0px",
              }}
              onClick={() => {
                setDisplayEnglishMaintenance(true)
              }}
            >
              <img src={worldIcon} style={{ marginRight: "0.5rem" }} />
              ENG
            </button>
          </div>
     
              <Navbar t={t} />
            </Grid>
          </Grid>
        </AppBar>

        <Drawer
          variant="permanent"
          open={open}
          style={{
            background: "white",
            boxShadow: "7px 4px 6.4px 0px #00000040",
          }}
        >
          <DrawerHeader style={{ background: "white" }}>
            {!open ? (
              <IconButton onClick={handleDrawerOpen} style={{ color: "black" }}>
                <MenuIcon />
              </IconButton>
            ) : (<>
         
          <div 
          style={{
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            backgroundColor: "black", // Agrega el fondo verde aquí
            //width: "120px",           // Opcional: Ajusta el tamaño del contenedor si es necesario
            //height: "120px"
           
          }}
        >
          <img 
            src={logo}
            style={{ 
              filter: "invert(100%)",
              width: "100px"
            }} 
          />
        </div>
              <IconButton onClick={handleDrawerOpen} style={{ color: "black" }}>
                <ChevronLeftIcon />
              </IconButton>
              </>
            )}
          </DrawerHeader>
          <List style={{ padding: open ? 13 : 7 }}>
            <ListItem
              disablePadding
              sx={{
                display: "block",
                borderRadius: 7,
                marginBottom: open ? 5 : 1,
                boxShadow: "0px 4px 4px 0px #00000040",
                background: "white",
              }}
            >
              <ListItemButton
                to="/home"
                sx={{
                  minHeight: 48,
                  borderRadius: "34px",
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src={homeIcon} style={{ color: "#59B089" }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <p
                      className="main-font"
                      style={{ fontWeight: 700, margin: 0 }}
                    >
                      {t("nav.home")}
                    </p>
                  }
                  sx={{
                    opacity: open ? 1 : 0,
                    color: "#59B089",
                  }}
                />
              </ListItemButton>
            </ListItem>

            {props.token && <>{renderNav(props.token)}</>}
          </List>
        </Drawer>

        <Box
          component="main"
          className="new-background"
          sx={{
            flexGrow: 1,
            height: "auto",
            padding: "80px 20px",
            maxWidth: { xs: "100%", md: "95%", lg: "95%" },
          }}
        >
          {props.children}
        </Box>
      </Box>
      <Footer />
    </div>
  );
}
