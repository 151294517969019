import jwt_decode from "jwt-decode";
import asyncComponent from "./../components/asyncComponent";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { ContactSupportOutlined } from "@mui/icons-material";

export const pageInit = {
  owner: "/home",
  developer: "/home",
  certifier: "/home",
  ovv: "/home",
  generalAccount: "/home",
  gov: "/home",
  passivesubject: "/home",
  retailAggregator: "/home",
  trader: "/home",
  broker: "/home",
};

export const routes = (token) => {
  const routes = [
    {
      path: "/",
      // document.body.classList.add('login-background');
      component: asyncComponent(() => import("../containers/HomeTwo")),
    },
    {
      path: "/home",
      // document.body.classList.add('login-background');
      component: asyncComponent(() => import("../components/Home/Homepage.js")),
    },
    {
      path: "/vintages",
      // document.body.classList.add('login-background');
      component: asyncComponent(() => import("../components/Project/ProjectVintageList.js")),
    },
    {
      path: "/about-us",
      // document.body.classList.add('login-background');
      component: asyncComponent(() => import("../components/Contact/AboutUs.js")),
    },
    {
      path: "/withdraw",
      // document.body.classList.add('login-background');
      component: asyncComponent(() => import("../components/Project/ProjectEmitCertificationGlobalList.js")),
    },
   
    {
      path: "/auth/:token",
      component: asyncComponent(() => import("../components/Security/Auth")),
    },
    {
      path: "welcome",
      component: asyncComponent(() =>
        import("../components/Project/ProjectList")
      ),
    },
    {
      path: "notifications",
      component: asyncComponent(() =>
        import("../components/Project/ProjectList")
      ),
    },
    {
      path: "/about",
      component: asyncComponent(() => import("../containers/About")),
    },
    {
      path: "/signature/:firm/:projectId/:userId",
      component: asyncComponent(() =>
        import("../components/Project/ProjectSignature")
      ),
    },
    {
      path: "/info-register-projects",
      component: asyncComponent(() =>
        import("../containers/InfoRegisterProject")
      ),
    },
    {
      path: "/info-trading",
      component: asyncComponent(() => import("../containers/InfoTrading")),
    },
    {
      path: "/newsletter/:newId",
      component: asyncComponent(() => import("../containers/Newsletter")),
    },
    {
      path: "/carbon-footprint-1",
      component: asyncComponent(() => import("../containers/CarbonFootprint1")),
    },
    {
      path: "/carbon-footprint-2",
      component: asyncComponent(() => import("../containers/CarbonFootprint2")),
    },
    {
      path: "/carbon-footprint-3",
      component: asyncComponent(() => import("../containers/CarbonFootprint3")),
    },
    {
      path: "/carbon-footprint-4",
      component: asyncComponent(() => import("../containers/CarbonFootprint4")),
    },
    {
      path: "/carbon-footprint-5",
      component: asyncComponent(() => import("../containers/CarbonFootprint5")),
    },
    {
      path: "/map-carbon-footprint",
      component: asyncComponent(() =>
        import("../containers/MapCabonFootprint")
      ),
    },

    {
      path: "/ecoregistry-new",
      component: asyncComponent(() => import("../containers/EcoregistryNew")),
    },
    {
      path: "/register-trading",
      component: asyncComponent(() =>
        import("../components/Project/RegisterTrading")
      ),
    },
    // {
    //   path: "/register-trading-cbl",
    //   component: asyncComponent(() =>
    //     import("../components/Project/RegisterTradingCbl")
    //   ),
    // },
    {
      path: "/projects",
      component: asyncComponent(() =>
        import("../components/Project/ProjectList")
      ),
    },
    /* {
      path: "/frequent-questions",
      component: asyncComponent(() =>
        import("../components/FrequentQuestions")
      ),
    }, */
    {
      path: "/projects/:projectId",
      component: asyncComponent(() =>
        import("../components/Project/ProjectView")
      ),
    },
    {
      path: "/emit-certification/:emitCertificationId",
      component: asyncComponent(() =>
        import("../components/Project/PDFEmitCertification")
      ),
    },
    {
      path: "/emit-certifications/:typeRemoval/:projectId",
      component: asyncComponent(() =>
        import("../components/Project/ProjectEmitCertificationList")
      ),
    },
    {
      path: "/downloadRetiredBonds/:projectId",
      component: asyncComponent(() =>
        import("../components/Project/ProjectEmitCertificationList")
      ),
    },
    {
      path: "/payment-pending-confirm",
      component: asyncComponent(() =>
        import("../containers/PaymentPendingConfirm")
      ),
    },
    /* {
      path: "/Trading",
      component: asyncComponent(() =>
        import("../components/Project/TradingClimateTrade")
      ),
    }, */
    // {
    //   path: "/TradingCbl",
    //   component: asyncComponent(() =>
    //     import("../components/Project/TradingCblMarkets")
    //   ),
    // },
    {
      path: "/TradingEcoTrade",
      component: asyncComponent(() =>
        import("../components/Project/TradingEcoTrade")
      ),
    },
    {
      path: "/project-comment",
      component: asyncComponent(() =>
        import("../components/Project/ProjectComment")
      ),
    },
    {
      path: "/project-comment/:standardId/:projectId",
      component: asyncComponent(() =>
        import("../components/Project/ProjectComment")
      ),
    },
    {
      path: "/contact",
      component: asyncComponent(() => import("../components/Contact/Contact")),
    },
    {
      path: "/create-account-process",
      component: asyncComponent(() =>
        import("../containers/CreateAccountProcess")
      ),
    },
    {
      path: "/notification",
      component: asyncComponent(() =>
        import("../components/Project/notification")
      ),
    },

    // {
    //   path: "/transfer-certification-exchange",
    //   component: asyncComponent(() => import("../components/Project/TransferCertificationExchange")),
    // },
    {
      path: "/correction-history/:projectId",
      component: asyncComponent(() =>
        import("../components/Corrections/CorrectionHistory")
      ),
    },
    {
      path: "/404",
      component: asyncComponent(() => import("../components/Error/Error404")),
    },
    
  ];
  return routes.concat(permits(token));
};

export const myAccount = (token) => {
  if (token) {
    const infoUser = jwt_decode(token);
    const permits = infoUser.permits;
    const exclude = infoUser.exclude;

    const nav = {
      owner: {
        adminUser: {
          position: "myAccount",
          order: 100,
          links: [
            {
              to: "/my-profile",
              label: "sideNav.user",
            },
          ],
        },
        project: {
          position: "projects",
          key: "project",
          order: 1,
          links: [
            {
              to: "/new-project",
              label: "sideNav.newProject",
            },
            {
              to: "/my-projects",
              label: "sideNav.subMyProject",
            },
            // {
            //   to: "/new-project-conversion",
            //   label: "sideNav.convertedFrom",
            // },
            // {
            //   to: "/new-project-credit-conversion",
            //   label: "sideNav.creditConversion",
            // },
          ],
        },
        transfer: {
          position: "positions",
          order: 2,
          links: [
            // {
            //   to: "/transfer-certification",
            //   label: "sideNav.transferCertification",
            // },
            {
              to: "/historical-transfer",
              label: "sideNav.historicalTransfer",
            },
            {
              to: "/transactions-request",
              label: "sideNav.transactionsOwner",
            },
            // {
            //   to: "/transactions-request",
            //   label: "sideNav.transactionsRequest",
            // },
            
          ],
        },
        // transactions: {
        //   position: "transactions",
        //   order: 2,
        //   links: [
        //     {
        //       to: "/transactions-request",
        //       label: "sideNav.transactionsRequest",
        //     },

        //   ],
        // },
        retire: {
          position: "positions",
          order: 2,
          links: [
            // {
            //   to: "/emit-certification",
            //   label: "sideNav.emitCertification",
            // },
          ],
        },
        public: {
          position: "projects",
          key: "project",
          order: 3,
          links: [
            {
              to: "/new-project",
              label: "sideNav.newProject",
            },
          ],
        },
      },
      retailAggregator: {
        adminUser: {
          position: "myAccount",
          order: 100,
          links: [
            {
              to: "/my-profile",
              label: "sideNav.user",
            },
          ],
        },
        transfer: {
          position: "positions",
          order: 1,
          links: [
            {
              to: "/transfer-certification",
              label: "sideNav.transferCertification",
            },
            {
              to: "/historical-transfer",
              label: "sideNav.historicalTransfer",
            },
          ],
        },
        retire: {
          position: "positions",
          order: 2,
          links: [
            {
              to: "/emit-certification",
              label: "sideNav.emitCertification",
            },
          ],
        },
        public: {
          position: "projects",
          key: "project",
          order: 3,
          links: [
            {
              to: "/new-project",
              label: "sideNav.newProject",
            },
          ],
        },
      },
      developer: {
        adminUser: {
          position: "myAccount",
          order: 100,
          links: [
            {
              to: "/my-profile",
              label: "sideNav.user",
            },
          ],
        },
        project: {
          position: "projects",
          order: 1,
          links: [
            {
              to: "/new-project",
              label: "sideNav.newProject",
            },
            {
              to: "/my-projects",
              label: "sideNav.subMyProject",
            }

            // {
            //   to: "/new-project-conversion",
            //   label: "sideNav.convertedFrom",
            // },
            // {
            //   to: "/new-project-credit-conversion",
            //   label: "sideNav.creditConversion",
            // },
          ],
        },
        transfer: {
          position: "positions",
          order: 2,
          links: [
            // {
            //   to: "/transfer-certification",
            //   label: "sideNav.transferCertification",
            // },
            {
              to: "/historical-transfer",
              label: "sideNav.historicalTransfer",
            },
            {
              to: "/transactions-request",
              label: "sideNav.transactionsOwner",
            },
          ],
        },
        retire: {
          position: "positions",
          order: 2,
          links: [
            // {
            //   to: "/emit-certification",
            //   label: "sideNav.emitCertification",
            // },
          ],
        },
        public: {
          position: "projects",
          key: "project",
          order: 3,
          links: [
            {
              to: "/new-project",
              label: "sideNav.newProject",
            },
          ],
        },
      },
      certifier: {
        adminUser: {
          position: "myAccount",
          order: 100,
          links: [
            {
              to: "/my-profile",
              label: "sideNav.user",
            },
          ],
        },
        project: {
          position: "projects",
          order: 1,
          links: [
            {
              to: "/my-projects",
              label: "sideNav.subMyProject",
            },
          ],
        },
        transactions: {
          position: "transactions",
          order: 2,
          links: [
            {
              to: "/transactions",
              label: "sideNav.transactions",
            },
            {
              to: "/transactions-request",
              label: "sideNav.transactionsRequest",
            },
          ],
        },
      },
      trader: {
        adminUser: {
          position: "myAccount",
          order: 100,
          links: [
            {
              to: "/my-profile",
              label: "sideNav.user",
            },
          ],
        },
        project: {
          position: "projects",
          order: 1,
          links: [],
        },
        transfer: {
          position: "positions",
          order: 2,
          links: [
            {
              to: "/historical-transfer",
              label: "sideNav.historicalTransfer",
            },
          ],
        },
        retire: {
          position: "positions",
          order: 3,
          links: [
           
          ],
        },
      },

      generalAccount: {
        adminUser: {
          position: "myAccount",
          order: 100,
          links: [
            {
              to: "/my-profile",
              label: "sideNav.user",
            },
          ],
        },
        project: {
          position: "projects",
          order: 1,
          links: [
            {
              to: "/my-projects",
              label: "sideNav.subMyProject",
            },
            {
              to: "/new-project",
              label: "sideNav.newProject",
            },
            // {
            //   to: "/new-project-conversion",
            //   label: "sideNav.convertedFrom",
            // },
            // {
            //   to: "/new-project-credit-conversion",
            //   label: "sideNav.creditConversion",
            // },
          ],
        },
        transfer: {
          position: "positions",
          order: 2,
          links: [
            {
              to: "/transfer-certification",
              label: "sideNav.transferCertification",
            },
            {
              to: "/historical-transfer",
              label: "sideNav.historicalTransfer",
            },
          ],
        },
        retire: {
          position: "positions",
          order: 3,
          links: [
            {
              to: "/emit-certification",
              label: "sideNav.emitCertification",
            },
          ],
        },
        public: {
          position: "projects",
          key: "project",
          order: 3,
          links: [
            {
              to: "/new-project",
              label: "sideNav.newProject",
            },
          ],
        },
      },
      gov: {
        adminUser: {
          position: "myAccount",
          order: 100,
          links: [
            {
              to: "/my-profile",
              label: "sideNav.user",
            },
          ],
        },
        project: {
          position: "projects",
          order: 1,
          links: [
            {
              to: "/emit-certification-report",
              label: "sideNav.emitCertificationReport",
            },
          ],
        },
        public: {
          position: "projects",
          key: "project",
          order: 3,
          links: [
            {
              to: "/new-project",
              label: "sideNav.newProject",
            },
          ],
        },
      },
      passivesubject: {
        adminUser: {
          position: "myAccount",
          order: 100,
          links: [
            {
              to: "/my-profile",
              label: "sideNav.user",
            },
          ],
        },
        project: {
          position: "projects",
          order: 1,
          links: [
            {
              to: "/emit-certification-report",
              label: "sideNav.emitCertificationReport",
            },
          ],
        },
        public: {
          position: "projects",
          key: "project",
          order: 3,
          links: [
            {
              to: "/new-project",
              label: "sideNav.newProject",
            },
          ],
        },
      },
      ovv: {
        adminUser: {
          position: "myAccount",
          order: 100,
          links: [
            {
              to: "/my-profile",
              label: "sideNav.user",
            },
          ],
        },
        project: {
          position: "projects",
          order: 1,
          links: [
            {
              to: "/my-projects",
              label: "sideNav.subMyProject",
            },
          ],
        },
        public: {
          position: "projects",
          key: "project",
          order: 3,
          links: [
            {
              to: "/new-project",
              label: "sideNav.newProject",
            },
          ],
        },
      },
    };

    let navTypeAccount = nav[infoUser.typeAccount];
    if (!navTypeAccount) {
      return [];
    }

    const navTypeAccountFilter = {};
    Object.keys(navTypeAccount).map(
      (ele) =>
        (navTypeAccountFilter[ele] = {
          ...navTypeAccount[ele],
          links: Object.keys(exclude).includes(ele)
            ? navTypeAccount[ele].links.filter(
                (link) => !exclude[ele].includes(link.to)
              )
            : navTypeAccount[ele].links,
        })
    );

    navTypeAccount = navTypeAccountFilter;

    let myAccountNav = {
      myAccount: {
        order: 100,
        links: [],
      },
    };
    if (infoUser.typeAccount === "trader") {
      permits.push("project");
    }

    for (const idx in permits) {
      const permit = permits[idx];
      if (navTypeAccount[permit]) {
        if (!myAccountNav[navTypeAccount[permit].position])
          myAccountNav[navTypeAccount[permit].position] = {
            order: navTypeAccount[permit].order,
            links: [],
          };
        myAccountNav[navTypeAccount[permit].position].links = myAccountNav[
          navTypeAccount[permit].position
        ].links.concat(navTypeAccount[permit].links);
      }
    }
    const navEnd = Object.keys(myAccountNav)
      .map((e) => ({
        name: e,
        order: myAccountNav[e].order,
        links: myAccountNav[e].links,
      }))
      .sort((a, b) => {
        if (a.order < b.order) {
          return -1;
        }
        return 0;
      });
    return navEnd;
  }
  return [];
};

export const myTrading = (token) => {
  if (token) {
    const infoUser = jwt_decode(token);
    const permits = infoUser.permits;
    const nav = {
      owner: {
        adminUser: [],
        project: [],
        exchange: [
          /* {
            to: "/Trading",
            label: "ClimateTrade",
          }, */
          // {
          //   to: "/TradingCbl",
          //   label: "CBL Markets",
          // },
          // {
          //   to: "/TradingEcotrade",
          //   label: "Ecotrade",
          // },
          // {
          //   to: "/transfer-certification-exchange",
          //   label: "Exchange",
          // },
        ],
        retire: [],
      },
      developer: {
        adminUser: [],
        project: [],
        exchange: [
          /* {
            to: "/Trading",
            label: "ClimateTrade",
          }, */
          // {
          //   to: "/TradingCbl",
          //   label: "CBL Markets",
          // },
          // {
          //   to: "/TradingEcotrade",
          //   label: "Ecotrade",
          // },
          // {
          //   to: "/transfer-certification-exchange",
          //   label: "Exchange",
          // },
        ],
        retire: [],
      },
      generalAccount: {
        adminUser: [],
        project: [],
        exchange: [
          /* {
            to: "/Trading",
            label: "ClimateTrade",
          }, */
          // {
          //   to: "/TradingCbl",
          //   label: "CBL Markets",
          // },
          // {
          //   to: "/TradingEcotrade",
          //   label: "Ecotrade",
          // },
          // {
          //   to: "/transfer-certification-exchange",
          //   label: "Exchange",
          // },
        ],
        retire: [],
      },
    };
    const navTypeAccount = nav[infoUser.typeAccount];
    if (!navTypeAccount) {
      return [];
    }

    let myAccountNav = [];
    for (const idx in permits) {
      const permit = permits[idx];
      if (navTypeAccount[permit]) {
        myAccountNav = myAccountNav.concat(navTypeAccount[permit]);
      }
    }
    return myAccountNav;
  }
  return [];
};

export const links = (token) => {
  return [
    {
      to: "/vintages",
      label: "sideNav.vintages",
      show: !token,
      icon: "list"
    },
    {
      to: "/withdraw",
      label: "sideNav.withdrawal",
      show: !token,
      icon: "list"
    },
    {
      to: "/projects",
      label: "projects",
      show: true,
      icon: "list",
    },
    {
      to: "/about-us",
      label: "sideNav.about",
      show: !token,
      icon: "list"
    },
    {
      to: "/register",
      label: "registerHome",
      show: !token,
      icon: "list",
    },
    {
      to: "/login",
      label: "logIn",
      show: !token,
      icon: "list",
    },

  ];
};

export const linksNav = (token) => {
  return [
    {
      to: "#",
      label: "sideNav.functioning",
      show: true,
      childNodes: [
        {
          to: "/about",
          label: "sideNav.about",
          show: true,
        },
        {
          to: "/create-account-process",
          label: "sideNav.CreateAccountProcess",
          show: true,
        },
        {
          to: "/info-register-projects",
          label: "sideNav.infoRegisterProject",
          show: true,
        },

        {
          to: "/notifications",
          label: "sideNav.contact",
          show: true,
        },
      ],
    },
    {
      to: "/projects",
      label: "projects",
      show: true,
      childNodes: [
        {
          to: "/about",
          label: "sideNav.about",
          show: true,
        },
        {
          to: "/create-account-process",
          label: "sideNav.CreateAccountProcess",
          show: true,
        },
        {
          to: "/info-register-projects",
          label: "sideNav.infoRegisterProject",
          show: true,
        },

        {
          to: "/notifications",
          label: "sideNav.contact",
          show: true,
        },
      ],
    },

    {
      to: "/contact",
      label: "sideNav.contact",
      show: !token,
    },
  ];
};

const permits = (token) => {
  if (token) {
    const infoUser = jwt_decode(token);
    const permits = infoUser.permits;

    const components = {
      owner: {
        adminUser: [
          {
            path: "/my-profile",
            component: asyncComponent(() =>
              import("../components/User/UserList")
            ),
          },
          {
            path: "/users/new",
            component: asyncComponent(() => import("../components/User/User")),
          },
        ],
        project: [
          {
            path: "/new-project",
            component: asyncComponent(() =>
              import("../components/Project/Project")
            ),
          },

          // {
          //   path: "/new-project-two",
          //   component: asyncComponent(() =>
          //     import("../components/Project/ProjectTwo")
          //   ),
          // },
          {
            path: "/new-project-conversion",
            component: asyncComponent(() =>
              import("../components/Project/ProjectConvertedFrom")
            ),
          },
          {
            path: "/new-project-credit-conversion",
            component: asyncComponent(() =>
              import("../components/Project/ProjectCreditConversion")
            ),
          },
          {
            path: "/my-projects",
            component: asyncComponent(() =>
              import("../components/Project/MyProjects")
            ),
          },
          {
            path: "/project/edit/:projectId",
            component: asyncComponent(() =>
              import("../components/Project/Project")
            ),
          },
          {
            path: "/project/edit-project-conversion/:projectId",
            component: asyncComponent(() =>
              import("../components/Project/ProjectConvertedFrom")
            ),
          },
          {
            path: "/project/edit-credit-conversion/:projectId",
            component: asyncComponent(() =>
              import("../components/Project/ProjectCreditConversion")
            ),
          },
        ],
        transfer: [
          {
            path: "/transfer-certification",
            component: asyncComponent(() =>
              import("../components/Project/TransferCertificationProject")
            ),
          },
          {
            path: "/historical-transfer",
            component: asyncComponent(() =>
              import("../components/Project/HistoricalTransfer")
            ),
          },
          {
            path: "/transactions-request",
            component: asyncComponent(() =>
              import("../components/Project/MyRequests")
            ),
          },
          {
            path: "/transfer-certification/:projectId/:serialId/:quantity",
            component: asyncComponent(() =>
              import("../components/Project/TransferCertificationProject")
            ),
          },
          {
            path: "/my-positions",
            component: asyncComponent(() =>
              import("../components/Project/MyPositions")
            ),
          },
        ],
        retire: [
          {
            path: "/emit-certification",
            component: asyncComponent(() =>
              import("../components/Project/EmitCertificationProject")
            ),
          },
          {
            path: "/emit-certification/:projectId/:serialId/:quantity",
            component: asyncComponent(() =>
              import("../components/Project/EmitCertificationProject")
            ),
          },
          {
            path: "/my-positions",
            component: asyncComponent(() =>
              import("../components/Project/MyPositions")
            ),
          },
        ],
      },
      developer: {
        adminUser: [
          {
            path: "/my-profile",
            component: asyncComponent(() =>
              import("../components/User/UserList")
            ),
          },
          {
            path: "/users/new",
            component: asyncComponent(() => import("../components/User/User")),
          },
        ],
        project: [
          {
            path: "/new-project",
            component: asyncComponent(() =>
              import("../components/Project/Project")
            ),
          },
          {
            path: "/my-projects",
            component: asyncComponent(() =>
              import("../components/Project/MyProjects")
            ),
          },
          {
            path: "/project/edit/:projectId",
            component: asyncComponent(() =>
              import("../components/Project/Project")
            ),
          },
          {
            path: "/project/edit-project-conversion/:projectId",
            component: asyncComponent(() =>
              import("../components/Project/ProjectConvertedFrom")
            ),
          },
          {
            path: "/project/edit-credit-conversion/:projectId",
            component: asyncComponent(() =>
              import("../components/Project/ProjectCreditConversion")
            ),
          },
        ],
        transfer: [
          {
            path: "/transfer-certification",
            component: asyncComponent(() =>
              import("../components/Project/TransferCertificationProject")
            ),
          },
          {
            path: "/historical-transfer",
            component: asyncComponent(() =>
              import("../components/Project/HistoricalTransfer")
            ),
          },
          {
            path: "/transfer-certification/:projectId/:serialId/:quantity",
            component: asyncComponent(() =>
              import("../components/Project/TransferCertificationProject")
            ),
          },
          {
            path: "/transactions-request",
            component: asyncComponent(() =>
              import("../components/Project/MyRequests")
            ),
          },
          {
            path: "/my-positions",
            component: asyncComponent(() =>
              import("../components/Project/MyPositions")
            ),
          },
        ],
        retire: [
          {
            path: "/emit-certification",
            component: asyncComponent(() =>
              import("../components/Project/EmitCertificationProject")
            ),
          },
          {
            path: "/emit-certification/:projectId/:serialId/:quantity",
            component: asyncComponent(() =>
              import("../components/Project/EmitCertificationProject")
            ),
          },
          {
            path: "/my-positions",
            component: asyncComponent(() =>
              import("../components/Project/MyPositions")
            ),
          },
        ],
      },
      trader: {
        adminUser: [
          {
            path: "/my-profile",
            component: asyncComponent(() =>
              import("../components/User/UserList")
            ),
          },
          {
            path: "/users/new",
            component: asyncComponent(() => import("../components/User/User")),
          },
        ],
        transfer: [
          {
            path: "/transfer-certification",
            component: asyncComponent(() =>
              import("../components/Project/TransferCertificationProject")
            ),
          },
          {
            path: "/historical-transfer",
            component: asyncComponent(() =>
              import("../components/Project/HistoricalTransfer")
            ),
          },
          {
            path: "/transactions-request",
            component: asyncComponent(() =>
              import("../components/Project/MyRequests")
            ),
          },
          {
            path: "/transfer-certification/:projectId/:serialId/:quantity",
            component: asyncComponent(() =>
              import("../components/Project/TransferCertificationProject")
            ),
          },
          {
            path: "/my-positions",
            component: asyncComponent(() =>
              import("../components/Project/MyPositions")
            ),
          },
        ],
        retire: [
          {
            path: "/emit-certification",
            component: asyncComponent(() =>
              import("../components/Project/EmitCertificationProject")
            ),
          },
          {
            path: "/emit-certification/:projectId/:serialId/:quantity",
            component: asyncComponent(() =>
              import("../components/Project/EmitCertificationProject")
            ),
          },
          {
            path: "/my-positions",
            component: asyncComponent(() =>
              import("../components/Project/MyPositions")
            ),
          },
        ],
      },
      generalAccount: {
        adminUser: [
          {
            path: "/my-profile",
            component: asyncComponent(() =>
              import("../components/User/UserList")
            ),
          },
          {
            path: "/users/new",
            component: asyncComponent(() => import("../components/User/User")),
          },
        ],
        project: [
          {
            path: "/new-project",
            component: asyncComponent(() =>
              import("../components/Project/Project")
            ),
          },
          {
            path: "/new-project-conversion",
            component: asyncComponent(() =>
              import("../components/Project/ProjectConvertedFrom")
            ),
          },
          {
            path: "/new-project-credit-conversion",
            component: asyncComponent(() =>
              import("../components/Project/ProjectCreditConversion")
            ),
          },
          {
            path: "/my-projects",
            component: asyncComponent(() =>
              import("../components/Project/MyProjects")
            ),
          },
          {
            path: "/project/edit/:projectId",
            component: asyncComponent(() =>
              import("../components/Project/Project")
            ),
          },
          {
            path: "/project/edit-project-conversion/:projectId",
            component: asyncComponent(() =>
              import("../components/Project/ProjectConvertedFrom")
            ),
          },
          {
            path: "/project/edit-credit-conversion/:projectId",
            component: asyncComponent(() =>
              import("../components/Project/ProjectCreditConversion")
            ),
          },
        ],
        transfer: [
          {
            path: "/transfer-certification",
            component: asyncComponent(() =>
              import("../components/Project/TransferCertificationProject")
            ),
          },
          {
            path: "/historical-transfer",
            component: asyncComponent(() =>
              import("../components/Project/HistoricalTransfer")
            ),
          },
          {
            path: "/transfer-certification/:projectId/:serialId/:quantity",
            component: asyncComponent(() =>
              import("../components/Project/TransferCertificationProject")
            ),
          },
          {
            path: "/my-positions",
            component: asyncComponent(() =>
              import("../components/Project/MyPositions")
            ),
          },
        ],
        retire: [
          {
            path: "/emit-certification",
            component: asyncComponent(() =>
              import("../components/Project/EmitCertificationProject")
            ),
          },
          {
            path: "/emit-certification/:projectId/:serialId/:quantity",
            component: asyncComponent(() =>
              import("../components/Project/EmitCertificationProject")
            ),
          },
          {
            path: "/my-positions",
            component: asyncComponent(() =>
              import("../components/Project/MyPositions")
            ),
          },
        ],
      },
      certifier: {
        adminUser: [
          {
            path: "/my-profile",
            component: asyncComponent(() =>
              import("../components/User/UserList")
            ),
          },
          {
            path: "/users/new",
            component: asyncComponent(() => import("../components/User/User")),
          },
        ],
        project: [
          {
            path: "/my-projects",
            component: asyncComponent(() =>
              import("../components/Project/MyProjects")
            ),
          },
        ],
        transactions: [
          {
            path: "/transactions",
            component: asyncComponent(() =>
              import("../components/Project/MyTransactions")
            ),
          },
          {
            path: "/transactions-request",
            component: asyncComponent(() =>
              import("../components/Project/MyRequests")
            ),
          },
        ],
      },
      ovv: {
        project: [
          {
            path: "/my-projects",
            component: asyncComponent(() =>
              import("../components/Project/MyProjects")
            ),
          },
        ],
        adminUser: [
          {
            path: "/my-profile",
            component: asyncComponent(() =>
              import("../components/User/UserList")
            ),
          },
          {
            path: "/users/new",
            component: asyncComponent(() => import("../components/User/User")),
          },
        ],
      },
      retailAggregator: {
        adminUser: [
          {
            path: "/my-profile",
            component: asyncComponent(() =>
              import("../components/User/UserList")
            ),
          },
          {
            path: "/users/new",
            component: asyncComponent(() => import("../components/User/User")),
          },
        ],
        transfer: [
          {
            path: "/transfer-certification",
            component: asyncComponent(() =>
              import("../components/Project/TransferCertificationProject")
            ),
          },
          {
            path: "/historical-transfer",
            component: asyncComponent(() =>
              import("../components/Project/HistoricalTransfer")
            ),
          },
          {
            path: "/transfer-certification/:projectId/:serialId/:quantity",
            component: asyncComponent(() =>
              import("../components/Project/TransferCertificationProject")
            ),
          },
          {
            path: "/my-positions",
            component: asyncComponent(() =>
              import("../components/Project/MyPositions")
            ),
          },
        ],
        retire: [
          {
            path: "/emit-certification",
            component: asyncComponent(() =>
              import("../components/Project/EmitCertificationProject")
            ),
          },
          {
            path: "/emit-certification/:projectId/:serialId/:quantity",
            component: asyncComponent(() =>
              import("../components/Project/EmitCertificationProject")
            ),
          },
          {
            path: "/my-positions",
            component: asyncComponent(() =>
              import("../components/Project/MyPositions")
            ),
          },
        ],
      },
      gov: {
        adminUser: [
          {
            path: "/my-profile",
            component: asyncComponent(() =>
              import("../components/User/UserList")
            ),
          },
          {
            path: "/users/new",
            component: asyncComponent(() => import("../components/User/User")),
          },
        ],
        project: [
          {
            path: "/emit-certification-report",
            component: asyncComponent(() =>
              import("../components/Project/EmitCertificationReport")
            ),
          },
        ],
      },
      passivesubject: {
        adminUser: [
          {
            path: "/my-profile",
            component: asyncComponent(() =>
              import("../components/User/UserList")
            ),
          },
          {
            path: "/users/new",
            component: asyncComponent(() => import("../components/User/User")),
          },
        ],
        project: [
          {
            path: "/emit-certification-report",
            component: asyncComponent(() =>
              import("../components/Project/EmitCertificationReport")
            ),
          },
          
        ],
      },
    };
    const navTypeAccount = components[infoUser.typeAccount];
    if (!navTypeAccount) {
      return [];
    }
    let myAccountNav = [];
    for (const idx in permits) {
      const permit = permits[idx];
      if (navTypeAccount[permit]) {
        myAccountNav = myAccountNav.concat(navTypeAccount[permit]);
      }
    }
    return myAccountNav;
  }
  return [];
};
