import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import CustomButton from "../custom/CustomButton";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import FilterListIcon from "@mui/icons-material/FilterList";

export default function CustomImputSearch({ setFilter }) {
  const { t } = useTranslation();
  const [filterData, setFilterData] = useState(false);
  return (
    <Grid container spacing={3}>
      <Grid item md={6} style={{ display: "flex" }}>
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 400,
          }}
          elevation={4}
        >
          {/* <IconButton sx={{ p: '10px' }} aria-label="menu">
        <FilterListIcon />
      </IconButton> */}
          <InputBase
            style={{ fontFamily: "Montserrat" }}
            sx={{ ml: 1, flex: 1 }}
            placeholder="Buscar"
            onChange={(event) => {
              setFilterData(event.target.value);
            }}
            //inputProps={{ 'aria-label': 'search google maps' }}
          />
          {/* <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton> */}
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        </Paper>
        <CustomButton
          onClick={() => setFilter(filterData)}
          style={"green"}
          text={t("buttons.search")}
        />
      </Grid>
    </Grid>
  );
}
