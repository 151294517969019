import { faTelegram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import iconoLinkedin from "../../images/linkedin.png";
import iconoTwitter from "../../images/twitter.png";
import xmLogo from "../../images/xm-logo.png";
import karbonoa from "../../images/karbonoa.svg";
import iso27 from "../../images/iso-2700120136034.jpg";
import { getSaveSuscription } from "./../../actions/home";
import { getTermCondition } from "./../../actions/termCondition";
import { platform } from "./../../Style/index";
import { StyledFooter } from "./StyledFooter";
import Box from "@mui/system/Box";
import Typography from "@mui/material/Typography";
import whiteLogo from "./../../images/logo-colcx-white-2.svg";
import mailIcon from "./../../images/mail-icon.png";
import phoneLogoIcon from "./../../images/phone-logo.png";
import logIcon from "./../../images/log.png";
import arrowIcon from "./../../images/arrow-icon.png";
import { Grid } from "@mui/material";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      showTerms: false,
      showHabeasData: false,
      habeasData: "",
      termCondition: "",
      isAuthorized: true,
      isTerms: true,
    };
  }
  componentDidMount() {
    getTermCondition().then((response) => {
      if (response.status === 1) {
        response.termsConditions.forEach((element) => {
          this.setState({
            [element.name]: element.terms,
          });
        });
      }
    });
  }

  onChange =
    (name, check = false) =>
    (event) => {
      let value = "";
      if (!check) {
        value = event.target.value;
      } else {
        value = event.target.checked;
      }

      return this.setState({
        [name]: value,
      });
    };

  onClickSave = (email) => {
    const { isAuthorized, isTerms } = this.state;
    if (isAuthorized && isTerms) {
      getSaveSuscription(email).then((response) => {
        if (response.status === 1) {
          window.location.reload();
        }
      });
    }
  };
  handleClose = () => {
    this.setState({ showTerms: false, showHabeasData: false });
  };

  showTerms = () => {
    this.setState({ showTerms: true });
  };
  showHabeasData = () => {
    this.setState({ showHabeasData: true });
  };

  render() {
    const { t } = this.props;
    const { email } = this.state;
    return (
      <StyledFooter>
        <div className="container" id="footerId" style={{ margin: 0 }}>
          <div className="row" style={{ width: "100%" }}>
            <Box
              className="col-md-8 col-xs-12 col-sm-12"
              sx={{ pl: { xs: 2, md: 8 }, pr: { xs: 2, md: 15 } }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Box sx={{ p: 2 }}>
                    <img src={whiteLogo} style={{ width: "80%" }} />
                    <p style={{ color: "white" }}>
                      ColCX una marca de Canal Clima S.A.S
                    </p>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    display: "flex",
                    alignItems: "left",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ p: 0 }}>
                    <ul
                      style={{
                        listStyleType: "none",
                        paddingLeft: 0,
                        paddingTop: "1rem",
                      }}
                    >
                      <li style={{ color: "white" }}>
                        <h4>CONTÁCTANOS</h4>
                      </li>
                      <li style={{ color: "white" }}>
                        comercial.colcx@canalclima.com
                      </li>
                      <li style={{ color: "white" }}>+57 (1) 7 94 3999</li>
                      <li style={{ color: "white" }}>+57 310 2022341</li>
                      <li style={{ color: "white" }}>
                      Carrera 11B # 99 - 25 Piso 10 - Edificio WeWork
                      </li>
                    </ul>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <div
              className="col-md-4 col-xs-12 col-sm-12"
              style={{
                textAlign: "center",
                left: "30px",
                alignItems: "center",
                padding: "0px",
                width: "323px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {/* left karbonoa info */}
              <div
                style={{
                  background: "white",
                  display: "flex",
                  flexDirection: "row",
                  gap: "1px",
                  width: "323px",
                  justifyContent: "space-around",
                  boxShadow: "4px 4px 4px 0px #00000040 inset",
                  borderRadius: "50px 0px 0px 50px",
                  opacity: "0px",
                  padding: "1.5rem 0px 1.5rem 1.5rem",
                }}
              >
                {/* left karbonoa info */}
                <div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p
                      style={{
                        margin: "0px",
                        color: "black",
                        textAlign: "left",
                      }}
                    >
                      Un producto
                    </p>
                    <img
                      src={karbonoa}
                      style={{ marginTop: "0.3rem", height: "16px" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "0.3rem",
                    }}
                  >
                    <p
                      style={{
                        color: "black",
                        paddingRight: "0.5rem",
                        margin: 0,
                      }}
                    >
                      By
                    </p>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.xm.com.co/nuestra-empresa/innovacion"
                    >
                      <img src={xmLogo} style={{ height: "21px" }} />
                    </a>
                  </div>
                </div>
                {/* right iso image */}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={iso27} style={{ height: "5rem" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledFooter>
    );
  }
}
export default withTranslation()(Footer);
